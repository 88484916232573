import { useState, useEffect, useRef } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCircleUser,
  faUpRightFromSquare,
  faSun,
  faMoon,
} from '@fortawesome/free-solid-svg-icons'
import { getFromIndexedDB, removeFromIndexedDB } from '../utils/idbUtils'
import inforcerLogo from '../assets/inforcer-logo-rubik-new.svg'
import inforcerLogoDark from '../assets/inforcer-white-blue.svg'

const Navbar = ({ theme, setTheme, realTheme }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const [userProfile, setUserProfile] = useState(null)

  useEffect(() => {
    const fetchUserProfile = async () => {
      const profile = await getFromIndexedDB('userProfile')
      setUserProfile(profile)
    }

    fetchUserProfile()
  }, [])

  const dropdownRef = useRef(null)

  useEffect(() => {
    const handleClickOutside = event => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false)
      }
    }

    if (isDropdownOpen) {
      document.addEventListener('mousedown', handleClickOutside)
    } else {
      document.removeEventListener('mousedown', handleClickOutside)
    }

    return () => document.removeEventListener('mousedown', handleClickOutside)
  }, [isDropdownOpen])

  // Function to clear IndexedDB and update UI state
  const clearLocalOnboardingState = async () => {
    await removeFromIndexedDB('onboardingState')
    await removeFromIndexedDB('userProfile')

    window.location.reload()
  }

  return (
    <header className='border-b border-b-gray-300 lg:px-4 py-4 mb-4 '>
      <div className='flex items-center container mx-auto relative max-w-screen-xl px-4 max-xs:flex-col'>
        <div className='mr-auto max-xs:m-auto'>
          {realTheme === 'dark' ? (
            <img
              src={inforcerLogoDark}
              alt='Inforcer Logo'
              className='h-[38px]'
            />
          ) : (
            <img src={inforcerLogo} alt='Inforcer Logo' className='h-[38px]' />
          )}
        </div>
        <nav className='ml-auto flex items-center h-fit max-xs:m-auto max-xs:flex-col'>
          <ul className='mr-6 max-xs:mr-0 max-xs:mt-2'>
            <li>
              <a
                href='https://inforcer.com'
                target='_blank'
                rel='noreferrer'
                className='text-inforcer-navy dark:text-gray-200 hover:text-inforcer-cyan '
              >
                {' '}
                inforcer.com{' '}
                <FontAwesomeIcon
                  icon={faUpRightFromSquare}
                  className='text-sm ml-1'
                />
              </a>
            </li>
          </ul>
          <div className='relative ' ref={dropdownRef}>
            <button
              type='button'
              className='text-inforcer-navy dark:text-gray-200 flex items-center gap-3 h-fit'
              onClick={() => setIsDropdownOpen(prev => !prev)}
            >
              <FontAwesomeIcon
                className='text-3xl dark:text-white'
                icon={faCircleUser}
              />

              <p>
                Welcome,{' '}
                {userProfile?.displayName ||
                  userProfile?.userPrincipalName ||
                  'Guest'}
              </p>
            </button>

            {isDropdownOpen && (
              <div className='absolute right-0 top-[42px] mt-2  bg-white border border-gray-300 rounded-lg shadow-lg p-2 z-50 dark:bg-slate-900 dark:text-gray-200'>
                <p className='text-gray-700 px-3 py-2 border-b dark:text-gray-200'>
                  {userProfile?.userPrincipalName || 'Guest'}
                </p>
                <div className='flex items-center text-left px-3 py-3 dark:bg-slate-900 border-b'>
                  {realTheme === 'dark' ? (
                    <FontAwesomeIcon className='text-white' icon={faMoon} />
                  ) : (
                    <FontAwesomeIcon icon={faSun} />
                  )}
                  <select
                    className='w-full text-left px-3 cursor-pointer  outline-none dark:bg-slate-900'
                    value={theme}
                    onChange={e => setTheme(e.target.value)}
                  >
                    <option value='dark'>Dark Mode</option>
                    <option value='light'>Light Mode</option>
                  </select>
                </div>

                <button
                  className='w-full text-left px-3 py-2 hover:bg-gray-100 dark:hover:bg-slate-800'
                  onClick={clearLocalOnboardingState}
                >
                  Logout
                </button>
              </div>
            )}
          </div>
        </nav>
      </div>
    </header>
  )
}

export default Navbar
