import { useState, useEffect } from 'react'
import { PublicClientApplication } from '@azure/msal-browser'
import {
  commonLoginAuthority,
  configuration,
  cspAuthScopes,
  delegatedAdminAuthScopes,
} from '../config/OnboardingConfig'
import { getFromIndexedDB, saveToIndexedDB } from '../utils/idbUtils'

const defaultState = {
  cspEpaAdded: false,
  cspEpaRemoved: false,
  onboardingCode: null,
  accessToken: null,
  customers: [],
  customerProgress: [],
}

// Load from IndexedDB (async)
const loadState = async () => {
  const storedState = await getFromIndexedDB('onboardingState')
  return storedState || defaultState
}

const saveState = async (state, isRefresh = false) => {
  if (isRefresh) return state // No need to save if it's a refresh
  await saveToIndexedDB('onboardingState', state)
  return state
}

const encodeFormBody = jsonBody => {
  const formBody = []

  // eslint-disable-next-line guard-for-in, no-restricted-syntax
  for (const property in jsonBody) {
    const encodedKey = encodeURIComponent(property)
    const encodedValue = encodeURIComponent(jsonBody[property])
    formBody.push(`${encodedKey}=${encodedValue}`)
  }

  return formBody.join('&')
}

const cspPca = new PublicClientApplication(configuration)

export const MSApi = () => {
  const [state, setState] = useState(defaultState)
  const [loading, setLoading] = useState(true)
  const [loadingPhase, setLoadingPhase] = useState('Authenticating')

  const [error, setError] = useState()

  const [totalTenants, setTotalTenants] = useState(0)
  const [fetchedTenants, setFetchedTenants] = useState(0)
  const [isAuthenticating, setIsAuthenticating] = useState(false)
  const [initialised, setInitialised] = useState(false)

  useEffect(() => {
    const initialisePca = async () => await cspPca.initialize()
    initialisePca()
  }, [])

  useEffect(() => {
    const fetchState = async () => {
      const storedState = await loadState()
      setState(storedState)
      setInitialised(true)
      setLoading(false)
    }
    fetchState()
  }, [])

  const refreshCspPcaCredential = async (
    authority,
    scopes,
    acquireTokenSilent,
    prompt = 'select_account'
  ) => {
    let response

    if (acquireTokenSilent) {
      try {
        response = await cspPca.acquireTokenSilent({
          scopes,
          authority,
        })
      } catch (acquireTokenSilentError) {
        try {
          response = await cspPca.acquireTokenPopup({
            scopes,
            authority,
          })
        } catch (acquireTokenInteractiveError) {
          console.error(acquireTokenInteractiveError)
          setError('Failed to authenticate interactively')
        }
      }
    } else {
      try {
        response = await cspPca.acquireTokenPopup({
          scopes,
          authority,
          prompt,
        })
      } catch (interactiveLoginError) {
        console.error(interactiveLoginError)
        setError(
          interactiveLoginError || 'Failed to authenticate interactively'
        )
      }
    }

    if (response !== undefined && authority === commonLoginAuthority) {
      cspPca.setActiveAccount(response.account)
    }

    return response
  }

  const getCustomerSubscribedSkus = async (accessToken, customers) => {
    try {
      const responses = await Promise.all(
        customers.map(async customer => {
          const customerId = customer?.id
          try {
            const response = await fetch(
              `https://api.partnercenter.microsoft.com/v1/customers/${customerId}/subscribedskus`,
              {
                method: 'GET',
                headers: {
                  Authorization: `Bearer ${accessToken}`,
                },
              }
            )

            if (!response.ok) {
              return null
            }

            return await response.json() // Parse response JSON
          } catch (error) {
            return null // Return null for failed requests
          }
        })
      )

      return responses // Returns an array of results (or null if failed)
    } catch (error) {
      console.error('Batch fetch error:', error)
      return [] // Ensure the function always returns an array
    }
  }

  const verifyLoginAccess = async (userDomain, username) => {
    const response = await fetch(
      `https://fa-companion-prod.azurewebsites.net/api/VerifyLogin?code=X-2bZ-wTYqYAngwM5_283Y4hOF9reDgOPslB-KA7xgLGAzFu6CsDgQ==&Domain=${userDomain}&User=${username}`,
      {
        method: 'GET',
        'Content-Type': 'text/plain; charset=utf-8',
      }
    )
    return response
  }

  const uploadMetrics = async (
    domain,
    username,
    firstname,
    lastname,
    displayName,
    jsonBody
  ) => {
    const response = await fetch(
      `https://fa-companion-prod.azurewebsites.net/api/UpdateMetrics?code=ecSXh9Ra9bJtK_h7G_X-4F9qcTvCypRfgch7kWPml4UWAzFu6LugIQ==&domain=${domain}&username=${username}&firstname=${firstname}&lastname=${lastname}&displayName=${displayName}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(jsonBody),
      }
    )

    if (response.ok) {
      return true
    } else {
      return false
    }
  }

  const fetchPartnerCenterToken = async accessToken =>
    await fetch('https://api.partnercenter.microsoft.com/generatetoken', {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      body: encodeFormBody({ grant_type: 'jwt_token' }),
    })

  const getUserProfile = async token => {
    try {
      const response = await fetch('https://graph.microsoft.com/v1.0/me', {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      })

      if (!response.ok) throw new Error('Failed to fetch user profile')

      const data = await response.json()
      await saveToIndexedDB('userProfile', data)
    } catch (error) {
      console.error('Error fetching user profile:', error)
      return null
    }
  }

  const fetchPartnerCenterCustomers = async token =>
    await fetch(
      'https://api.partnercenter.microsoft.com/v1/customers?size=1000',
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )

  const fetchPartnerCenterDomains = async token => {
    let allResults
    let nextUrlToFetchFrom = 'https://graph.microsoft.com/beta/domains'

    // TODO - Refactor with recursion
    try {
      const resp = await fetch(nextUrlToFetchFrom, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      const respJson = await resp.json()
      nextUrlToFetchFrom = respJson['@odata.nextLink']
      allResults = respJson.value

      while (nextUrlToFetchFrom) {
        const nextResp = await fetch(nextUrlToFetchFrom, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        const respJson = await nextResp.json()
        allResults = allResults.concat(respJson.value)
        nextUrlToFetchFrom = respJson['@odata.nextLink']
      }
    } catch (delegatedRelationsError) {
      console.error(delegatedRelationsError)
      allResults = null
      setError('Error fetching domains')
    }

    return allResults
  }

  const fetchPartnerCenterCustomerDetails = async token => {
    let allResults
    let nextUrlToFetchFrom =
      'https://graph.microsoft.com/beta/tenantRelationships/delegatedAdminRelationships?$filter=status%20eq%20%27active%27&$select=displayName,status,customer,accessDetails,endDateTime,autoExtendDuration,id'

    // TODO - Refactor with recursion
    try {
      const resp = await fetch(nextUrlToFetchFrom, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      const respJson = await resp.json()
      nextUrlToFetchFrom = respJson['@odata.nextLink']
      allResults = respJson.value

      while (nextUrlToFetchFrom) {
        const nextResp = await fetch(nextUrlToFetchFrom, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        const respJson = await nextResp.json()
        allResults = allResults.concat(respJson.value)
        nextUrlToFetchFrom = respJson['@odata.nextLink']
      }
    } catch (delegatedRelationsError) {
      console.error(delegatedRelationsError)
      allResults = null
      setError('Error fetching delegated relationships')
    }

    return allResults
  }

  const buildGraphAPIBatches = (coreURI, IDs) => {
    /*
      Logic here is that the IDs table that gets parsed through is limited to batching 20 request
      Via the URI that is parsed through, for example.

      Before: /tenantRelationships/delegatedAdminRelationships/$id/accessAssignments 
      After: /tenantRelationships/delegatedAdminRelationships/07ce0496-efcd-4e71-ad93-cfb364bf5a68-5bc01670-ad55-4428-b57a-db56700ecc4f/accessAssignments

      This will create a response like 
      {
        id: "1"
        method: "GET"
        url: "/tenantRelationships/delegatedAdminRelationships/1adf6f61-0e5f-48ee-bbee-d50defc7bfa9-5bc01670-ad55-4428-b57a-db56700ecc4f/accessAssignments"
      }
      */
    const batchLimit = 20
    const batches = []
    let response = { requests: [] }

    IDs.forEach((id, index) => {
      const uri = coreURI.replace('$id', id)
      response.requests.push({
        id: (index + 1).toString(),
        method: 'GET',
        url: uri,
      })

      if (response.requests.length >= batchLimit || index + 1 >= IDs.length) {
        batches.push(response)
        response = { requests: [] }
      }
    })

    return batches
  }

  const fetchGraphAPIBatch = async (accessToken, jsonBody) => {
    const result = await fetch('https://graph.microsoft.com/beta/$batch', {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(jsonBody),
    })

    const respJson = await result.json()
    return respJson
  }

  const fetchGraphAPI = async (token, url) => {
    let nextUrlToFetchFrom = url
    let allResults
    try {
      const resp = await fetch(nextUrlToFetchFrom, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      const respJson = await resp.json()
      nextUrlToFetchFrom = respJson['@odata.nextLink']
      allResults = respJson.value

      while (nextUrlToFetchFrom) {
        const nextResp = await fetch(nextUrlToFetchFrom, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        const respJson = await nextResp.json()
        allResults = allResults.concat(respJson.value)
        nextUrlToFetchFrom = respJson['@odata.nextLink']
      }
    } catch (delegatedRelationsError) {
      console.error(delegatedRelationsError)
      allResults = null
      setError('Error fetching delegated relationships')
    }

    return allResults
  }

  const fetchPartnerCenterAccessAssignments = async (token, relationships) => {
    const coreURI =
      '/tenantRelationships/delegatedAdminRelationships/$id/accessAssignments'
    const IDs = relationships.map(relationship => relationship.id)
    const batches = buildGraphAPIBatches(coreURI, IDs)
    const results = await batches.map(
      async batch => await fetchGraphAPIBatch(token, batch)
    )

    // Awaiting fulfillment of promises for each post in array
    const processed = Promise.all(results).then(val => val)

    // Returning processed data with comments included
    return processed
  }

  const fetchSecurityGroup = async (id, token) => {
    const members = await fetchGraphAPI(
      token,
      `https://graph.microsoft.com/beta/groups/${id}/members?$select=id,userPrincipalName`
    )

    let nextUrlToFetchFrom = `https://graph.microsoft.com/beta/groups/${id}?$select=id,displayName`
    let allResults
    try {
      const resp = await fetch(nextUrlToFetchFrom, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      const respJson = await resp.json()
      nextUrlToFetchFrom = respJson['@odata.nextLink']
      allResults = respJson

      while (nextUrlToFetchFrom) {
        const nextResp = await fetch(nextUrlToFetchFrom, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        const respJson = await nextResp.json()
        allResults = allResults.concat(respJson)
        nextUrlToFetchFrom = respJson['@odata.nextLink']
      }
    } catch (delegatedRelationsError) {
      console.error(delegatedRelationsError)
      allResults = null
      setError('Error fetching delegated relationships')
    }

    const details = allResults

    return {
      members,
      details,
    }
  }

  const fetchPartnerCenterSecureScore = async token => {
    let allResults

    //  Use if want to restrict to X days
    const newDate = new Date()
    const minute = newDate.getUTCMinutes()
    const hour = newDate.getUTCHours()
    const day = newDate.getUTCDate() - 2
    const month = newDate.getUTCMonth() + 1
    const year = newDate.getUTCFullYear()
    const twoDaysAgo = `${year}-${month}-${day}T${hour}:${minute}:00.000Z`

    let nextUrlToFetchFrom =
      'https://graph.microsoft.com/beta/tenantRelationships/managedTenants/managedTenantSecureScores'

    // TODO - Refactor with recursion
    try {
      const resp = await fetch(nextUrlToFetchFrom, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      const respJson = await resp.json()
      nextUrlToFetchFrom = respJson['@odata.nextLink']
      allResults = respJson.value

      while (nextUrlToFetchFrom) {
        const nextResp = await fetch(nextUrlToFetchFrom, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        const respJson = await nextResp.json()
        allResults = allResults.concat(respJson.value)
        nextUrlToFetchFrom = respJson['@odata.nextLink']
      }
    } catch (delegatedRelationsError) {
      console.error(delegatedRelationsError)
      allResults = null
      setError('Error fetching delegated relationships')
    }

    return allResults
  }

  const getPartnerCenterGraphToken = async tenantId => {
    const graphResponse = await refreshCspPcaCredential(
      `https://login.microsoftonline.com/${tenantId}`,
      delegatedAdminAuthScopes,
      true,
      'consent'
    )

    const token = graphResponse.accessToken

    return token
  }

  const securityGroupIds = []

  // Temporary name!
  const sortCustomers = async (token, partnerCenterGraphToken) => {
    const customerResponse = await fetchPartnerCenterCustomers(token)
    if (!customerResponse.ok) {
      setError({
        title: 'Warning',
        type: 'warning',
        message: <p>Failed to retrieve partner center customers</p>,
      })
      setLoading(false)
      return {
        customerDetails: [],
        allDomains: [],
        secureScores: state.allSecureScores || [],
      }
    }

    const customers = (await customerResponse.json()).items
    if (customers.length === 0) {
      setError({
        title: 'Warning',
        type: 'warning',
        message: <p>No customers found for this account</p>,
      })
      setLoading(false)
      return {
        customerDetails: [],
        allDomains: [],
        secureScores: state.allSecureScores || [],
      }
    }

    // Fetch domains
    const allDomains = await fetchPartnerCenterDomains(partnerCenterGraphToken)
    if (!allDomains) {
      setError({
        title: 'Error',
        type: 'error',
        message: <p>An error occurred fetching domains</p>,
      })
      setLoading(false)
      return {
        customerDetails: [],
        allDomains: [],
        secureScores: state.allSecureScores || [],
      }
    }

    // Other fetches
    const incompleteDelegatedRelationships =
      await fetchPartnerCenterCustomerDetails(partnerCenterGraphToken)

    // Will return $batch results in unflattened array
    const unflattenedDelegatedRelationshipAssignments =
      await fetchPartnerCenterAccessAssignments(
        partnerCenterGraphToken,
        incompleteDelegatedRelationships
      )

    // Flatten the delegrated relationships
    const delegatedRelationshipAssignments = []
    unflattenedDelegatedRelationshipAssignments.map(subarray =>
      subarray?.responses.map(item =>
        delegatedRelationshipAssignments.push(item)
      )
    )

    // Add the security group assignments to the relationships found.
    const delegatedRelationships = incompleteDelegatedRelationships.map(
      incompleteRelationship => {
        const foundAssignments = delegatedRelationshipAssignments.flatMap(
          relationship => {
            const context = relationship.body?.['@odata.context']
            const foundID = context?.toString().split("'")[1]

            if (foundID === incompleteRelationship.id) {
              relationship?.body?.value.forEach(group => {
                if (
                  !securityGroupIds.includes(
                    group.accessContainer.accessContainerId
                  )
                ) {
                  securityGroupIds.push(group.accessContainer.accessContainerId)
                }
              })
              return relationship?.body?.value
            }
            return []
          }
        )

        return {
          ...incompleteRelationship,
          assignments: foundAssignments,
        }
      }
    )
    const secureScores = await fetchPartnerCenterSecureScore(
      partnerCenterGraphToken
    )

    await getUserProfile(partnerCenterGraphToken)

    if (!delegatedRelationships) {
      setError({
        title: 'Error',
        type: 'error',
        message: (
          <p>An error occurred fetching delegated admin relationships</p>
        ),
      })
      setLoading(false)
      return {
        customerDetails: [],
        allDomains: [],
        secureScores: state.allSecureScores || [],
      }
    }

    // Map and return processed customer details
    const customerDetails = customers.map(customer => {
      const allFoundRelationships = delegatedRelationships.filter(
        relationship => relationship?.customer?.tenantId === customer.id
      )
      const isActive = allFoundRelationships.some(
        relationship => relationship?.status === 'active'
      )

      // Consolidate relationships.
      const allUnifiedRoles = allFoundRelationships.map(
        relationship => relationship.accessDetails.unifiedRoles
      )
      const finalRoles = []
      allUnifiedRoles.forEach(group => {
        group.forEach(role => {
          if (
            !finalRoles.some(e => e.roleDefinitionId === role.roleDefinitionId)
          ) {
            finalRoles.push(role)
          }
        })
      })

      const endDate = allFoundRelationships[0]?.endDateTime || null
      const autoExtendDuration =
        allFoundRelationships[0]?.autoExtendDuration || null

      return {
        ...customer,
        allowDelegatedAccess: isActive,
        unifiedRoles: finalRoles,
        endDateTime: endDate,
        assignments: allFoundRelationships[0]?.assignments,
        autoExtendDuration,
        relationshipId: allFoundRelationships[0]?.id || null,
        eTag: allFoundRelationships[0]?.['@odata.etag'] || null,
      }
    })

    return { securityGroupIds, customerDetails, allDomains, secureScores }
  }

  const getCustomers = async () => {
    setLoading(true)
    setFetchedTenants(0) // Reset fetched tenants count
    setIsAuthenticating(true)
    setError(undefined)
    setLoadingPhase('Authenticating...')

    try {
      // Authenticate
      const authResponse = await refreshCspPcaCredential(
        commonLoginAuthority,
        cspAuthScopes,
        false,
        'select_account'
      )

      if (!authResponse) {
        setError({
          title: 'Error Authenticating',
          type: 'error',
          message: (
            <p>
              We were unable to authenticate this user with the associated
              Public Cloud Application. Ensure you're authenticating using the
              correct Client Admin account.
            </p>
          ),
        })
        setLoading(false)
        setIsAuthenticating(false)
        return
      }

      setLoadingPhase('Verifying...')
      const username = authResponse.account.username
      const domain = username.split('@')[1]

      const accessAllowed = await verifyLoginAccess(domain, username)

      if (!accessAllowed.ok) {
        setError({
          title: 'Warning',
          type: 'warning',
          message: <p>Login is not allowed. Please contact your sales rep.</p>,
        })
        setLoading(false)
        setIsAuthenticating(false)
        return
      }

      setLoadingPhase('Generating Partner Center Token...')
      // Get Partner Center token
      const tokenResponse = await fetchPartnerCenterToken(
        authResponse.accessToken
      )
      if (!tokenResponse.ok) {
        setError({
          title: 'Warning',
          type: 'warning',
          message: <p>Failed to retrieve partner center token</p>,
        })
        setLoading(false)
        setIsAuthenticating(false)
        return
      }

      setLoadingPhase('Generating Graph API Token...')
      const token = (await tokenResponse.json()).access_token
      const partnerCenterGraphToken = await getPartnerCenterGraphToken(
        authResponse.tenantId
      )

      // Call `sortCustomers` to fetch and process all customer data
      setLoadingPhase('Gathering GDAP Groups...')
      const { securityGroupIds, customerDetails, allDomains, secureScores } =
        await sortCustomers(token, partnerCenterGraphToken)

      setLoadingPhase('Matching GDAP Group Members...')
      // Awaiting fulfillment of promises for each post in array
      const securityGroups = await Promise.all(
        securityGroupIds.map(
          async id => await fetchSecurityGroup(id, partnerCenterGraphToken)
        )
      )

      setLoadingPhase('Loading Organization Details...')
      const brandingBaseURI = `https://graph.microsoft.com/beta/organization?$select=id,displayName`
      const brandingBase = await Promise.all(
        await fetchGraphAPI(partnerCenterGraphToken, brandingBaseURI)
      )
      const brandingURI = `https://graph.microsoft.com/beta/organization/${brandingBase[0]?.id}/branding?$select=backgroundImageRelativeUrl,bannerLogoRelativeUrl,cdnList,faviconRelativeUrl,squareLogoRelativeUrl,squareLogoDarkRelativeUrl`
      let brandingLinks

      setLoadingPhase('Fetching Brand...')
      let branding

      try {
        const resp = await fetch(brandingURI, {
          headers: {
            Authorization: `Bearer ${partnerCenterGraphToken}`,
            'Accept-Language': 0,
          },
        })
        brandingLinks = await resp.json()

        branding = {
          id: brandingBase[0]?.id,
          displayName: brandingBase[0]?.displayName,
          cdn: brandingLinks?.cdnList[0],
          background: brandingLinks?.backgroundImageRelativeUrl,
          logo: brandingLinks?.bannerLogoRelativeUrl,
          favicon: brandingLinks?.faviconRelativeUrl,
          squareLogo: brandingLinks?.squareLogoRelativeUrl,
          squareLogoDark: brandingLinks?.squareLogoDarkRelativeUrl,
        }
      } catch (delegatedRelationsError) {
        console.error(delegatedRelationsError)
        brandingLinks = null
      }

      // Authentication is complete, now fetching customers
      setIsAuthenticating(false)

      setTotalTenants(customerDetails.length)

      // Schedule each update separately so that re‑renders can happen in between
      customerDetails.forEach((_, i) => {
        setTimeout(() => {
          setFetchedTenants(prev => prev + 1)
        }, i * 100)
      })

      // Wait until the maximum scheduled delay has passed before turning off the loading state.
      // (Assuming 100ms per tenant; adjust as needed.)
      const maxDelay = Math.max(0, (customerDetails.length - 1) * 100)
      await new Promise(resolve => setTimeout(resolve, maxDelay))

      // First, update state synchronously
      const newState = {
        cspEpaAdded: true,
        accessToken: token,
        graphToken: partnerCenterGraphToken,
        domains: allDomains,
        allSecureScores: secureScores ? [...secureScores] : [],
        securityGroups,
        branding,
        customers: customerDetails.map(c => ({
          ...c,
          partnerCenter: true,
        })),
      }

      setState(newState)
      await saveState(newState)
    } catch (error) {
      console.error(error)
      setError({
        title: 'Error',
        type: 'error',
        message: <p>Failed to retrieve customers</p>,
      })
    } finally {
      setLoading(false)
    }
  }

  return {
    state,
    setState,
    error,
    loading,
    initialised,
    fetchedTenants,
    totalTenants,
    isAuthenticating,
    setIsAuthenticating,
    getCustomers,
    getCustomerSubscribedSkus,
    loadingPhase,
    uploadMetrics,
  }
}
