import { useEffect, useMemo, useState } from 'react'
import { MaterialReactTable } from 'material-react-table'
import { defaultCellSx, defaultTableProperties } from './config'

const ListTable = ({
  columns,
  data,
  selectedListItems = [],
  setSelectedListItems,
  uniqueKey,
  loading,
  enableRowSelection,
  enableMultiRowSelection,
  positionGlobalFilter,
  enableGlobalFilter,
  renderTopToolbar,
  enablePagination,
  columnOrder,
  initialState,
}) => {
  // Initialize row selection state as an empty object
  const [rowSelectionState, setRowSelectionState] = useState({})

  // Compute selected rows from selectedListItems prop
  const selectedRows = useMemo(() => {
    if (!Array.isArray(selectedListItems)) return {}
    return selectedListItems.reduce((acc, selectedItem) => {
      acc[selectedItem[uniqueKey]] = true
      return acc
    }, {})
  }, [selectedListItems, uniqueKey])

  // Update parent state when row selection changes
  useEffect(() => {
    if (setSelectedListItems) {
      const filteredData =
        data?.filter(entry => !!rowSelectionState[entry[uniqueKey]]) || []
      setSelectedListItems(filteredData)
    }
  }, [data, rowSelectionState, setSelectedListItems, uniqueKey])

  const tableColumnDefinitions = useMemo(
    () =>
      columns.map(
        ({
          header,
          accessor,
          cell,
          sx,
          muiTableHeadCellProps,
          muiTableBodyCellProps,
          ...rest
        }) => ({
          header,
          accessorKey: accessor,
          muiTableBodyCellProps: {
            sx: { ...defaultCellSx, ...sx },
            ...muiTableBodyCellProps,
          },
          muiTableHeadCellProps: {
            sx: { ...defaultCellSx, ...sx },
            ...muiTableHeadCellProps,
          },
          size: 100,
          Cell: cell,
          ...rest,
        })
      ),
    [columns]
  )

  return (
    <MaterialReactTable
      columns={tableColumnDefinitions}
      {...defaultTableProperties}
      renderTopToolbar={
        renderTopToolbar ?? defaultTableProperties.renderTopToolbar
      }
      positionGlobalFilter={positionGlobalFilter}
      getRowId={row => row[uniqueKey]}
      data={data}
      enableRowSelection={enableRowSelection}
      enableMultiRowSelection={enableMultiRowSelection}
      enableGlobalFilter={enableGlobalFilter}
      enablePagination={enablePagination}
      onRowSelectionChange={newRowSelection => {
        setRowSelectionState(newRowSelection)
      }}
      state={{ rowSelection: selectedRows, isLoading: loading }}
      initialState={{
        ...defaultTableProperties.initialState,
        ...initialState,
        columnOrder,
        pagination: {
          pageIndex: 0,
          pageSize: 15,
        },
      }}
      muiTableBodyRowProps={({ row }) => ({
        onClick: event => {
          if (
            event.target.tagName === 'A' ||
            event.target.closest('.no-select')
          ) {
            return
          }
          row.getToggleSelectedHandler()(event)
        },
        sx: { cursor: 'pointer' },
      })}
    />
  )
}

export default ListTable
