import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const AutoRenewModalSkeleton = () => (
  <div className='mb-2'>
    <Skeleton count={2} />
  </div>
)

export default AutoRenewModalSkeleton
