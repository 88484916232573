import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts'

const SecureScoreAreaChart = secureScoreHistory => {
  const { secureScore } = secureScoreHistory
  const { realTheme } = secureScoreHistory

  const chartColor = realTheme === 'dark' ? '#4686CE' : '#178BDB'

  return (
    <div className='flex items-center justify-center'>
      <ResponsiveContainer width='100%' height={160}>
        <AreaChart data={secureScore}>
          <CartesianGrid strokeDasharray='3 3' />
          <XAxis
            dataKey='day'
            label={{
              value: 'Days Ago',
              position: 'insideBottomLeft',
              dy: 20,
            }}
          />
          <YAxis
            dataKey='score'
            label={{
              value: 'Secure Score',
              angle: -90,
              dx: 5,
              dy: 60,
              position: 'insideLeft',
            }}
          />
          <Tooltip
            formatter={(value, name) => [`${value}`, ' Secure Score']}
            labelFormatter={label => `${label} Days Ago`}
          />
          <Legend />
          <Area
            type='monotone'
            dataKey='score'
            name='Secure Score Timeline'
            stroke={chartColor}
            fill={chartColor}
            fillOpacity={0.2}
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  )
}

export default SecureScoreAreaChart
