import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGlobe } from '@fortawesome/free-solid-svg-icons'

/* eslint-disable */
const CreateTableColumns = ({
  handleSelectedCustomer,
  formatAutoRenewalStatus,
  licenseDataExists,
  hasGlobalAdminRole,
}) => {
  const columns = [
    {
      header: 'Tenant Name',
      accessor: 'companyProfile.companyName',
      Cell: ({ row }) => (
        <a
          onClick={() => handleSelectedCustomer(row.original)}
          className='font-semibold cursor-pointer text-inforcer-navy dark:text-gray-200 flex items-center'
        >
          <span className='whitespace-nowrap'>
            {row.original.companyProfile.companyName}
          </span>
        </a>
      ),
    },
    {
      header: 'GDAP Status',
      accessor: 'gdapStatus',
      Cell: ({ row }) => {
        const { unifiedRoles, readable, missingRoles } = row.original
        let gdapStatus = 'No Relationship'
        if (unifiedRoles?.length > 0) {
          if (missingRoles?.length === 0) {
            gdapStatus = 'inforcer Ready'
          } else {
            gdapStatus = `Missing ${missingRoles?.length} Roles`
          }
        }
        return gdapStatus === 'inforcer Ready' ? (
          <span className='flex w-fit items-center justify-center rounded-full bg-emerald-100 px-2.5 py-0.5 text-emerald-700 dark:bg-emerald-700/30 dark:text-emerald-100'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              fill='none'
              viewBox='0 0 24 24'
              strokeWidth='1.5'
              stroke='currentColor'
              className='-ms-1 me-1.5 size-4'
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                d='M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z'
              />
            </svg>
            <p className='whitespace-nowrap text-sm'>inforcer Ready</p>
          </span>
        ) : gdapStatus === 'No Relationship' ? (
          <span className='flex w-fit items-center justify-center rounded-full bg-red-200 px-2.5 py-0.5 text-red-700 dark:bg-red-700/30 dark:text-red-100'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              fill='none'
              viewBox='0 0 24 24'
              strokeWidth='1.5'
              stroke='currentColor'
              className='-ms-1 me-1.5 size-4'
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                d='M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z'
              />
            </svg>
            <p className='whitespace-nowrap text-sm'>No Relationship</p>
          </span>
        ) : (
          <span className='flex w-fit items-center justify-center rounded-full bg-amber-100 px-2.5 py-0.5 text-amber-700 dark:bg-amber-700/30 dark:text-amber-100'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              fill='none'
              viewBox='0 0 24 24'
              strokeWidth='1.5'
              stroke='currentColor'
              className='-ms-1 me-1.5 size-4'
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                d='M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z'
              />
            </svg>
            <p className='whitespace-nowrap text-sm'>
              Missing {missingRoles?.length} Roles
            </p>
          </span>
        )
      },
      sortingFn: (rowA, rowB) => {
        const getStatus = row => {
          const { unifiedRoles, missingRoles } = row.original
          if (unifiedRoles?.length > 0) {
            return missingRoles?.length === 0
              ? 'inforcer Ready'
              : `Missing ${missingRoles?.length} Roles`
          }
          return 'No Relationship'
        }

        const valueA = getStatus(rowA)
        const valueB = getStatus(rowB)

        const order = ['No Relationship', 'Missing Roles', 'inforcer Ready']
        return order.indexOf(valueA) - order.indexOf(valueB)
      },
      filterFn: (row, id, filterValue) => {
        const { unifiedRoles, missingRoles } = row.original
        let gdapStatus = 'No Relationship'

        if (unifiedRoles?.length > 0) {
          gdapStatus =
            missingRoles?.length === 0
              ? 'inforcer Ready'
              : `Missing ${missingRoles?.length} Roles`
        }

        return gdapStatus.toLowerCase().includes(filterValue.toLowerCase())
      },
      enableSorting: true,
      enableColumnFilter: true,
    },
    {
      header: 'Security Group Status',
      accessor: 'securityGroupStatus',
      Cell: ({ row }) => {
        const groupStatus = row.original.groupStatus
        const missingRoles = row.original.groupMissingRoles || []

        if (!groupStatus || groupStatus === 'No Group Roles') {
          return (
            <span
              title={`Missing roles: ${missingRoles?.join(', ')}`}
              className='inline-flex items-center justify-center rounded-full bg-red-200 px-2.5 py-0.5 text-red-700 dark:bg-red-700/30 dark:text-red-100'
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                fill='none'
                viewBox='0 0 24 24'
                strokeWidth='1.5'
                stroke='currentColor'
                className='-ms-1 me-1.5 size-4'
              >
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  d='M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z'
                />
              </svg>
              <p className='whitespace-nowrap text-sm'>{groupStatus}</p>
            </span>
          )
        } else if (groupStatus.startsWith('Missing')) {
          return (
            <span
              title={`Missing roles: ${missingRoles?.join(', ')}`}
              className='inline-flex items-center justify-center rounded-full bg-amber-100 px-2.5 py-0.5 text-amber-700 dark:bg-amber-700/30 dark:text-amber-100'
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                fill='none'
                viewBox='0 0 24 24'
                strokeWidth='1.5'
                stroke='currentColor'
                className='-ms-1 me-1.5 size-4'
              >
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  d='M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z'
                />
              </svg>
              <p className='whitespace-nowrap text-sm'>{groupStatus}</p>
            </span>
          )
        } else {
          return (
            <span
              title={row.original.groupMissingRoles?.join(', ')}
              className='inline-flex items-center justify-center rounded-full bg-emerald-100 px-2.5 py-0.5 text-emerald-700 dark:bg-emerald-700/30 dark:text-emerald-100'
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                fill='none'
                viewBox='0 0 24 24'
                strokeWidth='1.5'
                stroke='currentColor'
                className='-ms-1 me-1.5 size-4'
              >
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  d='M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z'
                />
              </svg>
              <p className='whitespace-nowrap text-sm'>{groupStatus}</p>
            </span>
          )
        }
      },
      filterFn: (row, id, filterValue) => {
        const { groupRoles, groupMissingRoles } = row.original
        let gdapStatus = 'No Relationship'

        if (groupRoles?.length > 0) {
          gdapStatus =
            groupMissingRoles?.length === 0
              ? 'inforcer Ready'
              : `Missing ${groupMissingRoles?.length} Roles`
        }

        return gdapStatus.toLowerCase().includes(filterValue.toLowerCase())
      },
      size: 220,
      muiTableHeadCellProps: {
        sx: {
          whiteSpace: 'nowrap',
        },
      },

      sortingFn: (rowA, rowB) => {
        const getStatus = row => {
          const { groupRoles, groupMissingRoles } = row.original
          if (groupRoles?.length > 0) {
            return groupMissingRoles?.length === 0
              ? 'inforcer Ready'
              : `Missing ${groupMissingRoles?.length} Roles`
          }
          return 'No Relationship'
        }

        const valueA = getStatus(rowA)
        const valueB = getStatus(rowB)

        const order = ['No Group Roles', 'Missing Roles', 'inforcer Ready']
        return order.indexOf(valueA) - order.indexOf(valueB)
      },

      enableSorting: true,
      enableColumnFilter: true,
    },
    {
      header: 'Secure Score',
      accessor: 'companyProfile.secureScorePercentage',
      Cell: ({ row }) => {
        const score = parseFloat(row.original.secureScorePercentage)
        return (
          <span className='whitespace-nowrap dark:text-gray-200'>
            {Number.isNaN(score) ? 'N/A' : `${score.toFixed(1)}%`}
          </span>
        )
      },
      sortingFn: (rowA, rowB) => {
        const scoreA = parseFloat(rowA.original.secureScorePercentage) || 0
        const scoreB = parseFloat(rowB.original.secureScorePercentage) || 0
        return scoreA - scoreB
      },
      filterFn: (row, id, filterValue) => {
        const score = parseFloat(row.original.secureScorePercentage) || 0
        return score.toString().includes(filterValue)
      },
      enableSorting: true,
      enableColumnFilter: true,
    },

    {
      header: 'Auto Renewal',
      accessor: 'autoExtendDuration',
      Cell: ({ row }) => {
        const isGlobalAdmin = hasGlobalAdminRole(row.original)
        const status = row.original.autoExtendDuration // 'P180D' for On, 'PT0S' for Off
        // Combine the logic
        if (status === 'P180D') {
          // Auto-Renew is On
          return (
            <span className='inline-flex items-center justify-center rounded-full bg-green-200 px-2.5 py-0.5 text-green-700 dark:bg-emerald-700/30 dark:text-emerald-100'>
              <p className='whitespace-nowrap text-sm'>On</p>
            </span>
          )
        } else {
          // status === 'PT0S' (Off)
          if (isGlobalAdmin) {
            // Off with Global Admin
            return (
              <span className='inline-flex items-center justify-center rounded-full bg-red-200 px-2.5 py-0.5 text-red-700 dark:bg-red-700/40 dark:text-red-100'>
                <p className='whitespace-nowrap text-sm'>
                  <FontAwesomeIcon
                    icon={faGlobe}
                    title='Global Admin Role Detected - Auto-renewal disabled'
                  />{' '}
                  Off
                </p>
              </span>
            )
          }
          // Off without Global Admin
          return (
            <span className='inline-flex items-center justify-center rounded-full bg-red-200 px-2.5 py-0.5 text-red-700 dark:bg-red-700/40 dark:text-red-100'>
              <p className='whitespace-nowrap text-sm'>Off</p>
            </span>
          )
        }
      },
      enableColumnFilter: true,
      filterFn: (row, id, filterValue) => {
        // If the user wants to filter by On, Off, or Off (GA)
        const rawStatus = row.original.autoExtendDuration
        const isGlobalAdmin = hasGlobalAdminRole(row.original)
        // Convert raw data to a plain string for filtering
        let displayStatus
        if (rawStatus === 'P180D') {
          displayStatus = 'On'
        } else if (rawStatus === 'PT0S') {
          displayStatus = isGlobalAdmin ? 'Off (GA)' : 'Off'
        } else {
          displayStatus = 'Unknown'
        }

        // Now match the displayStatus to the filterValue
        if (!filterValue) return true // no filter
        return displayStatus.toLowerCase().includes(filterValue.toLowerCase())
      },
      Filter: ({ column }) => {
        const handleChange = e => {
          column.setFilterValue(e.target.value)
        }

        return (
          <select
            onChange={handleChange}
            value={column.getFilterValue() || ''}
            className='rounded border px-2 py-1'
          >
            <option value=''>All</option>
            <option value='on'>On</option>
            <option value='off'>Off</option>
            <option value='off (ga)'>Off (Global Admin)</option>
          </select>
        )
      },
    },
  ]

  if (licenseDataExists) {
    columns.splice(4, 0, {
      header: 'Primary License',
      accessor: 'primaryLicense',
      Cell: ({ row }) => {
        const license = row.original.primaryLicense || 'N/A'
        return (
          <span className='whitespace-nowrap dark:text-gray-200'>
            {license}
          </span>
        )
      },
      sortingFn: (rowA, rowB) => {
        const valueA = rowA.original.primaryLicense || ''
        const valueB = rowB.original.primaryLicense || ''
        return valueA.localeCompare(valueB) // String sorting
      },
      enableSorting: true, // Sorting enabled
      filterFn: (row, columnId, filterValue) => {
        const license = row.original.primaryLicense || '' // Safely access the value
        return license.toLowerCase().includes(filterValue.toLowerCase()) // Case-insensitive match
      },
      enableColumnFilter: true,
    })
  }

  return columns
}

export default CreateTableColumns
