import { useState, useEffect } from 'react'
import { PublicClientApplication } from '@azure/msal-browser'
import { MSApi } from '../components/MSApi'
import MSLogin from '../components/MSLogin'
import Report from './Report'
import { configuration } from '../config/MSConfig'
import Navbar from '../components/Navbar'
import SelectedCustomer from './SelectedCustomer'
import TenantLoadingScreen from './TenantLoadingScreen'
import Spinner from '../components/Spinner'
import {
  saveToIndexedDB,
  getFromIndexedDB,
  sanitizeForIndexedDB,
} from '../utils/idbUtils'
import { mergeGroupsIntoCustomers } from '../utils/utils'

export const pca = new PublicClientApplication(configuration)

const PartnerCenterAssessment = () => {
  const {
    getCustomers,
    getCustomerSubscribedSkus,
    state,
    isAuthenticating,
    fetchedTenants,
    totalTenants,
    error,
    loading,
    initialised,
    loadingPhase,
    uploadMetrics,
  } = MSApi()

  const [customers, setCustomers] = useState([])
  const [allSecureScores, setAllSecureScores] = useState([])
  const [securityGroups, setSecurityGroups] = useState([])
  const [customerGroups, setCustomerGroups] = useState([])
  const [licenseLoading, setLicenseLoading] = useState(false)
  const [selectedCustomer, setSelectedCustomer] = useState(false)
  const [theme, setTheme] = useState(
    localStorage.getItem('inforcerCompanionTheme') || ''
  )
  const [realTheme, setRealTheme] = useState(null)
  const [themeLoading, setThemeLoading] = useState(true)

  // Dark mode detection and application

  useEffect(() => {
    const root = document.documentElement
    const isSystemDark = window.matchMedia(
      '(prefers-color-scheme: dark)'
    ).matches

    // Get the theme from localStorage or use system preference
    const getLocalTheme = localStorage.getItem('inforcerCompanionTheme')
    if (!getLocalTheme) {
      // If no theme is set in localStorage, use system preference
      const detectedTheme = isSystemDark ? 'dark' : 'light'
      localStorage.setItem('inforcerCompanionTheme', detectedTheme)
      setTheme(detectedTheme)
      setRealTheme(detectedTheme)
    } else {
      // Apply the user's chosen theme
      setTheme(getLocalTheme)
      setRealTheme(getLocalTheme)
    }
    setThemeLoading(false)
  }, []) // Runs once on app load

  useEffect(() => {
    const root = document.documentElement

    // Apply the theme dynamically whenever it changes
    root.classList.toggle('dark', theme === 'dark')
    localStorage.setItem('inforcerCompanionTheme', theme)
    setRealTheme(theme)
  }, [theme])

  useEffect(() => {
    console.log('Change to customers detected')
  }, [customers])

  useEffect(() => {
    if (state && Array.isArray(state?.customers)) {
      setCustomers(state.customers)
    } else {
      setCustomers([])
    }

    if (Array.isArray(state?.allSecureScores)) {
      setAllSecureScores(state.allSecureScores)
    } else {
      setAllSecureScores([])
    }
    if (Array.isArray(state?.securityGroups)) {
      setSecurityGroups(state.securityGroups)
    }
  }, [state])

  const updateCache = async data => {
    const currentData = (await getFromIndexedDB('onboardingState')) || {}
    currentData.customers = sanitizeForIndexedDB(data)

    await saveToIndexedDB('onboardingState', currentData)

    return currentData
  }

  const updateCacheSingle = async customer => {
    const currentData = (await getFromIndexedDB('onboardingState')) || {}

    // Update customer in array
    const customersTemp = currentData.customers.map(ele =>
      ele.id === customer.id ? customer : ele
    )

    setCustomers(customersTemp)
    await updateCache(customersTemp)
  }

  useEffect(() => {
    if (customers && securityGroups) {
      const merged = mergeGroupsIntoCustomers(customers, securityGroups)
      setCustomers(merged)
    }
  }, [securityGroups])

  const sendMetrics = async body => {
    const firstLogin = (await getFromIndexedDB('firstLogin')) || null
    const userProfile = (await getFromIndexedDB('userProfile')) || null

    if (firstLogin === null && userProfile !== null) {
      // This is the first login, do stuff.
      const username = userProfile.userPrincipalName.toString()
      const domain = username.split('@')[1]

      const success = await uploadMetrics(domain, body)

      if (success) {
        // Prevent resending on subsequent updates
        await saveToIndexedDB('firstLogin', {
          firstLogin: false,
        })
      }
    }
  }

  const getTenantLicenses = async customersList => {
    if (!customersList || customersList.length === 0) {
      console.warn('No customers available for license retrieval.')
      return
    }

    if (licenseLoading === false) {
      setLicenseLoading(true)

      const batchSize = 20 // Adjust based on API limits
      const customersToProcess = Array.isArray(customersList)
        ? customersList.filter(ele => ele.readable)
        : []

      if (customersToProcess.length === 0) {
        console.warn('No readable customers found.')
        setLicenseLoading(false)
        return
      }

      const batches = []
      for (let i = 0; i < customersToProcess.length; i += batchSize) {
        batches.push(customersToProcess.slice(i, i + batchSize))
      }

      let updatedCustomers = [...customersList] // Keep track of changes

      for (const batch of batches) {
        try {
          // Fetch SKUs for the batch
          const batchResults = await getCustomerSubscribedSkus(
            state.accessToken,
            batch
          )

          // Process each customer in the batch concurrently using Promise.all
          const processedBatch = await Promise.all(
            batch.map(async (customer, index) => {
              const licenses = batchResults[index]?.items || null
              const updatedCustomer = {
                ...customer,
                state: licenses ? 2 : 3, // Success (2) or failed (3) state
                licenses,
              }

              // Immediately update the `customers` state
              setCustomers(prevCustomers =>
                prevCustomers.map(c =>
                  c.id === updatedCustomer.id ? updatedCustomer : c
                )
              )

              await updateCacheSingle(updatedCustomer)
              return updatedCustomer
            })
          )

          // Merge updates into the full list
          updatedCustomers = updatedCustomers.map(
            customer =>
              processedBatch.find(c => c.id === customer.id) || customer
          )

          // Short delay after processing each batch to allow state updates to settle
          await new Promise(resolve => setTimeout(resolve, 20))
        } catch (error) {
          console.error('Batch processing error:', error)
        }
      }

      setCustomers(updatedCustomers)
      await updateCache(updatedCustomers)

      setLicenseLoading(false)
    }
  }

  const getCustomersRefresh = async () => {
    await getCustomers()
  }

  const handleSelectedCustomer = customerId => {
    setSelectedCustomer(customerId)
    window.scrollTo(0, 0)
  }

  const clearSelectedCustomer = () => {
    setSelectedCustomer(false)
    window.scrollTo(0, 0)
  }

  if (themeLoading) {
    // Render a loading spinner or placeholder while the theme is being initialized
    return (
      <div className='flex items-center flex-col justify-center h-screen'>
        <p>Initialising Theme...</p>
      </div>
    )
  }

  return (
    <div className='App bg-white dark:bg-slate-900 dark:text-white'>
      {!initialised ? (
        <div className='flex items-center flex-col justify-center h-screen'>
          <Spinner loadingText='Loading app...' />
        </div>
      ) : (
        <>
          {!loading && customers?.length > 0 && (
            <Navbar theme={theme} setTheme={setTheme} realTheme={realTheme} />
          )}
          {!loading && customers?.length === 0 && (
            <MSLogin
              onClick={getCustomersRefresh}
              error={error}
              realTheme={realTheme}
            />
          )}
          <div className='container mx-auto max-w-screen-xl'>
            {loading && (
              <TenantLoadingScreen
                isAuthenticating={isAuthenticating}
                fetchedTenants={fetchedTenants}
                totalTenants={totalTenants}
                error={error}
                loadingPhase={loadingPhase}
                realTheme={realTheme}
              />
            )}

            {!loading && customers?.length > 0 && selectedCustomer && (
              <SelectedCustomer
                selectedCustomer={selectedCustomer}
                allSecureScores={allSecureScores}
                clearCustomer={clearSelectedCustomer}
                realTheme={realTheme}
              />
            )}

            {!loading && customers?.length > 0 && !selectedCustomer && (
              <Report
                sendMetrics={sendMetrics}
                customers={customers}
                setCustomers={setCustomers}
                customerGroups={customerGroups}
                allSecureScores={allSecureScores}
                onCustomerSelected={handleSelectedCustomer}
                getTenantLicenses={getTenantLicenses}
                loading={loading}
                licenseLoading={licenseLoading}
                getCustomersRefresh={getCustomersRefresh}
                realTheme={realTheme}
              />
            )}
          </div>
        </>
      )}
    </div>
  )
}

export default PartnerCenterAssessment
