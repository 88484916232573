import { useState, useEffect, useRef, useMemo } from 'react'
/* eslint-disable camelcase */
import { MRT_GlobalFilterTextField } from 'material-react-table'
/* eslint-enable camelcase */
import {
  faDownload,
  faGear,
  faChevronDown,
} from '@fortawesome/free-solid-svg-icons'
import { CSVLink } from 'react-csv'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { minimumRoles, globalAdminRole } from '../config/MSConfig'
import CreateTableColumns from '../components/ListTable/CreateTableColumns'
import ListTable from '../components/ListTable/ListTable'
import LicenseBarChart from '../components/Charts/LicenseBarChart'
import PDFExportButton from '../components/PDFExportButton'
import Spinner from '../components/Spinner'

import CalculateAverageSecureScore from '../components/CalculateAverageSecureScore'
import AverageSecureScoreAreaChart from '../components/Charts/AverageSecureScoreLineChart'
import GenericPieChart from '../components/Charts/GenericPieChart'
import Modal from '../components/Modal/Modal'
import TableActionsModal from '../components/ListTable/TableActionsModal'
import AutoRenewDialog from '../components/AutoRenewDialog'
import useAutoRenew from '../hooks/useAutoRenew'
import { calculateUnconsumedLicenses } from '../utils/utils'
import { getFromIndexedDB } from '../utils/idbUtils'
import useRemoveGA from '../hooks/useRemoveGA'
import RemoveGADialog from '../components/RemoveGADialog'

const Report = ({
  customers,
  setCustomers,
  customerGroups,
  sendMetrics,
  onCustomerSelected,
  getTenantLicenses,
  loading,
  licenseLoading,
  allSecureScores,
  updateCustomersOnly,
  realTheme,
}) => {
  const chartRef = useRef(null)

  const handleSelectedCustomer = customer => {
    onCustomerSelected(customer)
  }

  const [customerCount, setCustomerCount] = useState(0)
  const [gdapEnabledCustomers, setGdapEnabledCustomers] = useState(0)
  const [gdapReadyCustomers, setGdapReadyCustomers] = useState(0)
  const [gdapReadyWithSecurityGroups, setGdapReadyWithSecurityGroups] =
    useState(0)
  const [tenantsWithBusinessE1, setTenantsWithBusinessE1] = useState(0)
  const [tenantsWithBusinessE3, setTenantsWithBusinessE3] = useState(0)
  const [tenantsWithBusinessE5, setTenantsWithBusinessE5] = useState(0)
  const [tenantsWithBusinessBasic, setTenantsWithBusinessBasic] = useState(0)
  const [tenantsWithBusinessStandard, setTenantsWithBusinessStandard] =
    useState(0)
  const [tenantsWithBusinessPremium, setTenantsWithBusinessPremium] =
    useState(0)
  const [customerTable, setCustomerTable] = useState('')
  const [averageSecureScore, setAverageSecureScore] = useState(0)
  const [selectedListItems, setSelectedListItems] = useState([])
  const [modalOpen, setModalOpen] = useState(false)
  const [processingCustomers, setProcessingCustomers] = useState(true)
  const [metricsSent, setMetricsSent] = useState(false)

  const [actionMenuOpen, setActionMenuOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const [bulkAction, setBulkAction] = useState(null)

  const processCustomers = async () => {
    if (customers) {
      let gdapEnabledCount = 0
      let gdapReadyCount = 0
      let businessPremiumCount = 0
      let businessStandardCount = 0
      let businessBasicCount = 0
      let E1Count = 0
      let E3Count = 0
      let E5Count = 0
      let totalSecureScorePercentage = 0
      let totalSecureScoreTenants = 0

      setCustomerCount(customers?.length)

      const licenseTier = {
        'Microsoft 365 Business Premium': 12,
        'M365 E5': 11,
        'M365 F5': 10,
        'M365 A5': 9,
        'Microsoft 365 Business Standard': 8,
        'M365 E3': 7,
        'M365 F3': 6,
        'M365 A3': 5,
        'Microsoft 365 Business Basic': 4,
        'M365 E1': 3,
        'M365 F1': 2,
        'M365 A1': 1,
        Other: 0, // Default for unknown licenses
      }

      const updatedCustomerTable = customers.map(ele => {
        const missingRoles = []
        let missingRolesHTML = <p>Loading...</p>
        let secureScorePercentage = 0

        allSecureScores?.forEach(secureScore => {
          if (secureScore.tenantId === ele?.id) {
            secureScorePercentage =
              (secureScore.currentScore / secureScore.maxScore) * 100
          }
        })
        // License Counting Logic
        const licenseCount = {}
        let primaryLicense = 'None'

        ele?.licenses?.forEach(license => {
          const licenseName = license.productSku?.name || 'Other'
          licenseCount[licenseName] = (licenseCount[licenseName] || 0) + 1
        })

        let maxCount = 0
        Object.keys(licenseCount).forEach(licenseName => {
          const count = licenseCount[licenseName]
          if (
            count > maxCount ||
            (count === maxCount &&
              licenseTier[licenseName] > licenseTier[primaryLicense])
          ) {
            maxCount = count
            primaryLicense = licenseName
          }
        })

        // Assign Primary License
        ele.primaryLicense = primaryLicense // eslint-disable-line no-param-reassign

        if (secureScorePercentage > 0) {
          totalSecureScoreTenants++
          totalSecureScorePercentage += secureScorePercentage
        }

        if (totalSecureScoreTenants > 0) {
          const calculatedSecureScore =
            totalSecureScorePercentage / totalSecureScoreTenants
          setAverageSecureScore(calculatedSecureScore)
        } else {
          setAverageSecureScore(0) // Default to 0 if no tenants are valid
        }

        // Increment GDAP Enabled count
        if (ele?.unifiedRoles?.length > 0) gdapEnabledCount += 1

        // Check for Global Admin role
        const hasGlobalAdmin = ele?.unifiedRoles?.some(
          customerRole => globalAdminRole.id === customerRole.roleDefinitionId
        )

        // Check for Business Premium license
        const hasBusinessPremium = ele?.licenses?.some(
          license =>
            license.productSku.name === 'Microsoft 365 Business Premium' ||
            license.productSku?.sku === 'SBP'
        )
        if (hasBusinessPremium) businessPremiumCount += 1

        // Check for Business Standard License
        const hasBusinessStandard = ele?.licenses?.some(
          license =>
            license.productSku.name === 'Microsoft 365 Business Standard' ||
            license.productSku?.sku === 'O365_BUSINESS_PREMIUM'
        )
        if (hasBusinessStandard) businessStandardCount += 1

        // Check for Business Basic License
        const hasBusinessBasic = ele?.licenses?.some(
          license =>
            license.productSku.name === 'Microsoft 365 Business Basic' ||
            license.productSku?.sku === 'O365_BUSINESS_ESSENTIALS'
        )
        if (hasBusinessBasic) businessBasicCount += 1

        // Check for M365 E1
        const hasM365E1 = ele?.licenses?.some(
          license =>
            license.productSku.name === 'Office 365 E1' ||
            license.productSku?.sku === 'STANDARDPACK'
        )
        if (hasM365E1) E1Count += 1

        // Check for M365 E3
        const hasM365E3 = ele?.licenses?.some(
          license =>
            license.productSku.name === 'Microsoft 365 E3' ||
            license.productSku?.sku === 'SPE_E3'
        )
        if (hasM365E3) E3Count += 1

        // Check for M365 E3
        const hasM365E5 = ele?.licenses?.some(
          license =>
            license.productSku.name === 'Microsoft 365 E5' ||
            license.productSku?.sku === 'SPE_E5'
        )
        if (hasM365E5) E5Count += 1

        if (!hasGlobalAdmin) {
          minimumRoles.forEach(role => {
            const validRole = ele?.unifiedRoles?.some(
              customerRole => role.id === customerRole.roleDefinitionId
            )

            if (!validRole) missingRoles.push(role.displayName)
          })

          if (missingRoles?.length === 0) {
            gdapReadyCount += 1
            ele.readable = true // eslint-disable-line no-param-reassign
            missingRolesHTML = <p>There are no missing roles!</p>
          } else {
            missingRolesHTML = (
              <div className='mx-auto max-w-3xl text-center'>
                <h1 className='mb-2 mt-0 text-3xl font-medium leading-tight text-primary'>
                  Missing GDAP Roles
                </h1>
                <ul>
                  {missingRoles.map((role, index) => (
                    <li key={`${ele?.id}-${index}`}>{role}</li>
                  ))}
                </ul>
              </div>
            )
          }
        } else {
          gdapReadyCount += 1
          ele.readable = true // eslint-disable-line no-param-reassign
          missingRolesHTML = (
            <p>
              This customer has the <b>Global Administrator</b> role assigned.
            </p>
          )
        }

        let missingRoleBadge

        /* eslint-disable no-param-reassign */
        ele.missingRoles = missingRoles
        ele.secureScorePercentage = secureScorePercentage.toFixed(1)
        ele.missingRoleBadge = missingRoleBadge
        ele.missingRolesHTML = missingRolesHTML
        /* eslint-enable no-param-reassign */

        return ele
      })

      // Set final counts in state once processing is complete
      setGdapEnabledCustomers(gdapEnabledCount)
      setGdapReadyCustomers(gdapReadyCount)
      setTenantsWithBusinessE1(E1Count)
      setTenantsWithBusinessE3(E3Count)
      setTenantsWithBusinessE5(E5Count)
      setTenantsWithBusinessBasic(businessBasicCount)
      setTenantsWithBusinessStandard(businessStandardCount)
      setTenantsWithBusinessPremium(businessPremiumCount)
      setCustomerTable(updatedCustomerTable)
      setProcessingCustomers(false)
    }
  }

  // Check if any primaryLicense is not 'None'
  const hasValidPrimaryLicense = customers?.some(customer =>
    customer.licenses?.every(license => {
      const licenseName = license.productSku?.name
      return licenseName !== 'None'
    })
  )

  useEffect(() => {
    processCustomers()
    if (!hasValidPrimaryLicense) {
      getTenantLicenses(customers)
    }
  }, [customers])

  const calculateDaysRemaining = endDateTime => {
    if (!endDateTime) return 'N/A'
    const endDate = new Date(endDateTime)
    const currentDate = new Date()
    const timeDifference = endDate - currentDate
    const daysRemaining = Math.ceil(timeDifference / (1000 * 60 * 60 * 24))
    return daysRemaining >= 0 ? daysRemaining : 'Expired'
  }

  const hasGlobalAdminRole = customer =>
    customer?.unifiedRoles?.some(
      role => role.roleDefinitionId === '62e90394-69f5-4237-9190-012177145e10'
    )

  const formatAutoRenewalStatus = (status, id, asText = false) => {
    if (asText) {
      // Return filterable plain text
      return status === 'P180D' ? 'On' : 'Off'
    }

    // Return JSX for rendering
    return status === 'P180D' ? (
      <span className='inline-flex items-center justify-center rounded-full bg-green-200 px-2.5 py-0.5 text-green-700'>
        <p className='whitespace-nowrap text-sm'>On</p>
      </span>
    ) : (
      <span className='inline-flex items-center justify-center rounded-full bg-red-200 px-2.5 py-0.5 text-red-700'>
        <p className='whitespace-nowrap text-sm'>Off</p>
      </span>
    )
  }

  const tenantReportData =
    customers?.map(data => {
      const GdapState = data => {
        let state = 'No Relationship'
        if (data?.unifiedRoles?.length > 0) {
          if (data.missingRoles?.length === 0) {
            state = 'inforcer Ready'
          } else {
            state = `Missing ${data.missingRoles?.length} Roles`
          }
        }
        return state
      }

      return {
        tenantId: data?.companyProfile.tenantId ?? '',
        domain: data?.companyProfile.domain ?? '',
        companyName: data?.companyProfile.companyName ?? '',
        gdapDaysRemaining: calculateDaysRemaining(data.endDateTime) ?? '',
        gdapStatus: GdapState(data) ?? '',
        missingRoles: data.missingRoles ?? '',
        // autoRenewalStatus:
        //   formatAutoRenewalStatus(data?.autoExtendDuration) ?? '',
        unconsumedLicenses: calculateUnconsumedLicenses(data.licenses) ?? '',
        primaryLicense: data.primaryLicense ?? '',
      }
    }) ?? []

  const allTenantsLicensesEmpty =
    tenantsWithBusinessBasic === 0 &&
    tenantsWithBusinessStandard === 0 &&
    tenantsWithBusinessPremium === 0 &&
    tenantsWithBusinessE1 === 0 &&
    tenantsWithBusinessE3 === 0 &&
    tenantsWithBusinessE5 === 0

  const tableColumns = CreateTableColumns({
    handleSelectedCustomer,
    formatAutoRenewalStatus,
    licenseDataExists: hasValidPrimaryLicense,
    hasGlobalAdminRole,
  })

  const enableLicenseAutoRenewUpdate = () => {
    if (!selectedListItems || selectedListItems.length === 0) return false

    const allP180D = selectedListItems.every(
      item => item.autoExtendDuration === 'P180D'
    )
    const allNonP180D = selectedListItems.every(
      item => item.autoExtendDuration === 'PT0S'
    )

    const allHaveValidIds = selectedListItems.every(
      item =>
        typeof item.relationshipId === 'string' &&
        item.relationshipId.trim() !== ''
    )

    const noGlobalAdminRole = selectedListItems.every(
      item => !hasGlobalAdminRole(item) // Ensure none of the selected items have the Global Admin role
    )

    // Enable the button if:
    // - All items are either all P180D or all non-P180D
    // - All items have valid IDs
    // - No selected item has the Global Admin role
    return (allP180D || allNonP180D) && allHaveValidIds && noGlobalAdminRole
  }

  const enableGlobalAdminRoleRemoval = () => {
    if (!selectedListItems || selectedListItems.length === 0) return false

    const allHaveGlobalAdminRole = selectedListItems.every(hasGlobalAdminRole)

    return allHaveGlobalAdminRole
  }

  const enableEllipsisButton = () => {
    // If no selected tenants, no ellipsis
    if (!selectedListItems || selectedListItems.length === 0) return false

    // If we can do auto-renew OR remove GA, enable the button
    const canAutoRenew = enableLicenseAutoRenewUpdate()
    const canRemoveGlobalAdmin = enableGlobalAdminRoleRemoval()

    return canAutoRenew || canRemoveGlobalAdmin
  }

  // Config tenant chart data
  const tenantChartData = useMemo(() => {
    const nonGdapCustomers = customerCount - gdapEnabledCustomers
    const gdapReadyButNotEnabled = gdapEnabledCustomers - gdapReadyCustomers

    return [
      { name: 'GDAP Enabled, inforcer Ready', value: gdapEnabledCustomers },
      {
        name: 'GDAP Enabled, not inforcer Ready',
        value: gdapReadyButNotEnabled,
      },
      { name: 'Non-GDAP Enabled Customers', value: nonGdapCustomers },
    ]
  }, [customerCount, gdapEnabledCustomers, gdapReadyCustomers])

  const tenantChartColors =
    realTheme === 'dark'
      ? ['#4686CE', '#102550', '#B5BAC1'] // Dark theme colors
      : ['#178BDB', '#102550', '#B5BAC1'] // Light theme colors

  // Sanitise/config data for secure score pie chart
  const cleanData = data =>
    data.filter(entry => entry && typeof entry === 'object')

  const averageSecureScoreChartData = cleanData(
    CalculateAverageSecureScore(allSecureScores)
  )

  const averageSecureScorePercentage = averageSecureScore.toFixed(1)

  const secureScoreChartData = useMemo(
    () => [
      {
        name: 'Average Secure Score',
        value: Math.round(averageSecureScore * 10) / 10,
      },
      {
        name: 'Remaining Secure Score',
        value: 100.0 - averageSecureScorePercentage,
      },
    ],
    [averageSecureScorePercentage]
  )

  const secureScoreChartColors =
    realTheme === 'dark' ? ['#4686CE', '#B5BAC1'] : ['#178BDB', '#B5BAC1']

  const getAutoRenewActionType = () => {
    if (!selectedListItems || selectedListItems.length === 0) return null

    const allP180D = selectedListItems.every(
      item => item.autoExtendDuration === 'P180D'
    )

    return allP180D ? 'disable' : 'enable'
  }

  const closeModalRefreshTenants = async () => {
    setAutoRenewResponses(null)
    setModalOpen(false)

    // Refresh customer list after updates
    const onboardingState = (await getFromIndexedDB('onboardingState')) ?? {}

    setCustomers(onboardingState.customers)
  }

  const {
    autoRenewLoading,
    autoRenewResponses,
    setAutoRenewResponses,
    handleEnableAutoRenew,
  } = useAutoRenew({
    getAutoRenewActionType,
    selectedListItems,
  })

  const {
    removeGALoading,
    removeGAResponses,
    setRemoveGAResponses,
    handleRemoveGlobalAdmin,
    pollResult,
  } = useRemoveGA({
    selectedListItems,
  })

  // Anchors action modal to button
  const menuOpen = Boolean(anchorEl)

  // Closes action modal when request modal is open
  useEffect(() => {
    if (modalOpen) {
      setAnchorEl(null)
    }
  }, [modalOpen])

  // Manage request modal conditions
  const handleActionChoice = action => {
    setBulkAction(action)
    setModalOpen(true)
  }

  if (licenseLoading === false && processingCustomers === false) {
    const body = {
      average_secure_score:
        Math.round(averageSecureScorePercentage * 100) / 10000,
      total_tenants: customerCount,
      total_gdap_tenants: gdapEnabledCustomers,
      valid_gdap_tenants: gdapReadyCustomers,
      total_m365_e1: tenantsWithBusinessE1,
      total_m365_e3: tenantsWithBusinessE3,
      total_m365_e5: tenantsWithBusinessE5,
      total_m365_business_basic: tenantsWithBusinessBasic,
      total_m365_business_standard: tenantsWithBusinessStandard,
      total_m365_business_premium: tenantsWithBusinessPremium,
    }
    sendMetrics(body)
  }

  return (
    <div className='Report px-4 pb-4 lg:px-4'>
      <div ref={chartRef}>
        <div className='flex justify-between mb-4 max-md:flex-col max-md:mb-0'>
          <div className='flex-2 mr-4 p-5 border border-gray-300 rounded-lg shadow-sm bg-white dark:bg-slate-900 max-md:mr-0 max-md:mb-4'>
            <GenericPieChart
              colors={tenantChartColors}
              data={tenantChartData}
              displayValue={customerCount}
              dataLabel='Tenants'
              realTheme={realTheme}
            />
          </div>
          <div className='flex-1 flex flex-col items-center p-5 border border-gray-300 rounded-lg shadow-sm bg-white max-md:mb-4 dark:bg-slate-900'>
            {/* try to add the clause - if primary license doesn't exist */}
            {allTenantsLicensesEmpty &&
            !hasValidPrimaryLicense &&
            !licenseLoading ? (
              <div className='flex justify-center items-center flex-col h-[100%]'>
                <p className='text-gray-500 mb-6'>License data unavailable.</p>
              </div>
            ) : loading || licenseLoading ? (
              <Spinner
                styles='mt-[100px]'
                loadingText='Loading tenant licenses...'
              />
            ) : (
              <LicenseBarChart
                customerCount={customerCount}
                tenantsWithBusinessBasic={tenantsWithBusinessBasic}
                tenantsWithBusinessStandard={tenantsWithBusinessStandard}
                tenantsWithBusinessPremium={tenantsWithBusinessPremium}
                tenantsWithBusinessE1={tenantsWithBusinessE1}
                tenantsWithBusinessE3={tenantsWithBusinessE3}
                tenantsWithBusinessE5={tenantsWithBusinessE5}
                realTheme={realTheme}
              />
            )}
          </div>
        </div>
        <div className='flex justify-between mb-4 max-md:flex-col'>
          <div className='flex-2 mr-4 p-5 border border-gray-300 rounded-lg shadow-sm bg-white dark:bg-slate-900 max-md:mr-0 max-md:mb-4'>
            <GenericPieChart
              colors={secureScoreChartColors}
              data={secureScoreChartData}
              displayValue={averageSecureScorePercentage}
              isPercentage
              dataLabel='Average Secure Score'
              realTheme={realTheme}
            />
          </div>
          <div className='flex-1 p-5 border border-gray-300 rounded-lg shadow-sm bg-white dark:bg-slate-900 max-md:mb-4'>
            <AverageSecureScoreAreaChart
              averageSecureScore={averageSecureScoreChartData}
              realTheme={realTheme}
            />
          </div>
        </div>
      </div>

      <ListTable
        uniqueKey='id'
        selectedListItems={selectedListItems}
        setSelectedListItems={setSelectedListItems}
        columns={tableColumns}
        data={customers ?? []}
        enableMultiRowSelection
        enableGlobalFilter
        enableColumnFilters
        loading={loading || licenseLoading}
        enableRowSelection
        enableColumnFilter
        renderTopToolbar={({ table }) => (
          <>
            <div className='flex justify-start items-center py-2 dark:text-gray-200'>
              <p>
                Selected Tenants:{' '}
                {Object.keys(table.getState().rowSelection).length}
              </p>
            </div>

            <div className='flex justify-between items-center py-2'>
              {/* eslint-disable-next-line react/jsx-pascal-case */}
              <MRT_GlobalFilterTextField table={table} />
              {/* eslint-enable-next-line react/jsx-pascal-case */}
              <div className='flex gap-3'>
                <button
                  type='button'
                  className={`w-25 h-5 py-5 px-3 inline-flex items-center justify-center rounded ${!enableEllipsisButton() ? 'bg-gray-200 text-gray-700 dark:bg-slate-700 dark:text-slate-300 ' : 'bg-inforcer-cyan dark:bg-dark-mode-primary text-white cursor-pointer'}`}
                  id='user-action-button'
                  disabled={!enableEllipsisButton()}
                  aria-expanded={actionMenuOpen}
                  aria-haspopup='true'
                  aria-label='bulk tenant actions'
                  onClick={e => {
                    setAnchorEl(e.currentTarget)
                  }}
                >
                  <FontAwesomeIcon className='text-xl' icon={faGear} />
                  {/* Modify
                  <FontAwesomeIcon
                    className='ml-3 text-xs'
                    icon={faChevronDown}
                  /> */}
                </button>
                <TableActionsModal
                  anchorEl={anchorEl}
                  setAnchorEl={setAnchorEl}
                  menuOpen={menuOpen}
                  setActionMenuOpen={setActionMenuOpen}
                  selectedListItems={selectedListItems}
                  setModalOpen={setModalOpen}
                  enableLicenseAutoRenewUpdate={enableLicenseAutoRenewUpdate}
                  enableGlobalAdminRoleRemoval={enableGlobalAdminRoleRemoval}
                  handleActionChoice={handleActionChoice}
                />

                <PDFExportButton
                  tenants={tenantReportData}
                  chartRef={chartRef}
                />
                <CSVLink
                  className={`flex items-center rounded border text-sm border-inforcer-cyan bg-inforcer-cyan px-2 py-2 text-white hover:bg-transparent hover:text-inforcer-cyan focus:outline-none focus:ring active:text-inforcer-cyan dark:bg-dark-mode-primary dark:hover:bg-transparent dark:hover:border-white dark:hover:text-white cursor-pointer ${
                    customers?.length === 0
                      ? 'pointer-events-none bg-gray-500 opacity-50'
                      : 'bg-inforcer-cyan '
                  }`}
                  data={tenantReportData}
                  filename='inforcer_gdap_assessment.csv'
                >
                  <FontAwesomeIcon className='mr-2' icon={faDownload} />
                  Export to CSV
                </CSVLink>
              </div>
            </div>
          </>
        )}
      />

      <Modal
        isOpen={modalOpen}
        setModalOpen={setModalOpen}
        dialogPanelWidth='w-4/5 max-w-[1240px]'
      >
        {bulkAction === 'removeGlobalAdmin' && (
          <RemoveGADialog
            selectedListItems={selectedListItems}
            handleRemoveGlobalAdmin={handleRemoveGlobalAdmin}
            setModalOpen={setModalOpen}
            removeGALoading={removeGALoading}
            removeGAResponses={removeGAResponses}
            setRemoveGAResponses={setRemoveGAResponses}
            closeModalRefreshTenants={closeModalRefreshTenants}
            pollResult={pollResult}
          />
        )}

        {bulkAction === 'updateAutoRenew' && (
          <AutoRenewDialog
            selectedListItems={selectedListItems}
            handleEnableAutoRenew={handleEnableAutoRenew}
            setModalOpen={setModalOpen}
            getAutoRenewActionType={getAutoRenewActionType}
            autoRenewLoading={autoRenewLoading}
            autoRenewResponses={autoRenewResponses}
            setAutoRenewResponses={setAutoRenewResponses}
            refreshCustomers={updateCustomersOnly}
            closeModalRefreshTenants={closeModalRefreshTenants}
          />
        )}
      </Modal>
    </div>
  )
}

export default Report
