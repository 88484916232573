/*eslint-disable*/
const CalculateAverageSecureScore = allSecureScores => {
  const scoreOverDays = []
  allSecureScores?.map(secureScore => {
    const currentDate = new Date()
    const dateString = secureScore.createdDateTime.split('T')[0]
    const date = new Date(dateString)
    const daysAgo = Math.floor((currentDate - date) / (60 * 60 * 1000 * 24))
    if (scoreOverDays[daysAgo] === undefined) {
      scoreOverDays[daysAgo] = []
    }
    scoreOverDays[daysAgo].push(
      Math.floor((secureScore.currentScore / secureScore.maxScore) * 1000) / 10
    )
  })

  const scoreOverDaysAverage = []
  let i = 0
  scoreOverDays?.map(dailySecureScores => {
    let x = 0
    let total = 0
    dailySecureScores.map(scores => {
      total += scores
      x++
    })
    const averageSecureScore = total / x
    scoreOverDaysAverage[i] = {
      score: Math.floor(averageSecureScore * 10) / 10,
      day: i,
    }
    i++
  })

  scoreOverDaysAverage?.reverse()

  return scoreOverDaysAverage
}

export default CalculateAverageSecureScore

// const CalculateAverageSecureScore = allSecureScores => {
//   const scoreOverDays = []
//   allSecureScores?.forEach(secureScore => {
//     const currentDate = new Date()
//     const dateString = secureScore.createdDateTime.split('T')[0]
//     const date = new Date(dateString)
//     const daysAgo = Math.floor((currentDate - date) / (60 * 60 * 1000 * 24))
//     if (scoreOverDays[daysAgo] === undefined) {
//       scoreOverDays[daysAgo] = []
//     }
//     scoreOverDays[daysAgo].push(
//       Math.floor((secureScore.currentScore / secureScore.maxScore) * 1000) / 10
//     )
//   })

//   const scoreOverDaysAverage = []
//   scoreOverDays?.forEach((dailySecureScores, i) => {
//     let total = 0
//     dailySecureScores.forEach(score => {
//       total += score
//     })
//     const averageSecureScore = total / dailySecureScores.length
//     scoreOverDaysAverage[i] = {
//       score: Math.floor(averageSecureScore * 10) / 10,
//       day: i,
//     }
//   })

//   scoreOverDaysAverage?.reverse()

//   return scoreOverDaysAverage
// }

// export default CalculateAverageSecureScore
