import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRepeat, faGlobe } from '@fortawesome/free-solid-svg-icons'
import { Menu } from '@mui/material'

const TableActionsModal = ({
  menuOpen,
  setAnchorEl,
  anchorEl,
  enableLicenseAutoRenewUpdate,
  enableGlobalAdminRoleRemoval,
  handleActionChoice,
}) => {
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <Menu
      open={menuOpen}
      anchorEl={anchorEl}
      onClose={handleClose}
      className='hiya'
    >
      <div className='flex flex-col justify-start px-2 items-start'>
        <button
          className='w-full text-left px-3 py-2 hover:bg-gray-100 dark:hover:bg-gray-800'
          onClick={() => handleActionChoice('updateAutoRenew')}
          disabled={!enableLicenseAutoRenewUpdate()}
        >
          {' '}
          <FontAwesomeIcon className='mr-2' icon={faRepeat} /> Update Auto-renew
          statuses
        </button>
        <button
          className='w-full text-left px-3 py-2 hover:bg-gray-100 dark:hover:bg-gray-800'
          onClick={() => handleActionChoice('removeGlobalAdmin')}
          disabled={!enableGlobalAdminRoleRemoval()}
        >
          {' '}
          <FontAwesomeIcon className='mr-2' icon={faGlobe} /> Remove Global
          Admin Roles
        </button>
      </div>
    </Menu>
  )
}

export default TableActionsModal
