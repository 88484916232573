import LinearProgress from '@mui/material/LinearProgress'
import inforcerLogo from '../assets/inforcer-shield-logo.png'
import inforcerLogoDark from '../assets/inforcer-shield-logo-dark-mode.svg'

const TenantLoadingScreen = ({
  isAuthenticating,
  fetchedTenants,
  totalTenants,
  error,
  loadingPhase,
  realTheme,
}) => {
  if (error) {
    return (
      <div className='error-screen h-screen flex flex-col justify-center items-center'>
        <p className='text-lg font-semibold text-red-600'>Error</p>
        <p>{error?.message}</p>
        <button
          className='mt-4 rounded border text-sm border-inforcer-cyan bg-inforcer-cyan px-4 py-2 text-white'
          onClick={() => window.location.reload()}
        >
          Try Again
        </button>
      </div>
    )
  }

  const progress = totalTenants > 0 ? (fetchedTenants / totalTenants) * 100 : 0
  const progressColor = realTheme === 'dark' ? '#4686CE' : '#178BDB'

  return (
    <div className='loading-screen h-screen flex flex-col justify-center max-w-xl mx-auto'>
      {realTheme === 'dark' ? (
        <img
          src={inforcerLogoDark}
          alt='Inforcer Logo'
          className='mx-auto h-14 w-auto mb-4'
        />
      ) : (
        <img
          src={inforcerLogo}
          alt='Inforcer Logo'
          className='mx-auto h-14 w-auto mb-4'
        />
      )}

      {isAuthenticating ? (
        <p className='text-center mt-2'>{loadingPhase}</p>
      ) : (
        <>
          <p className='text-center mb-2'>
            {fetchedTenants} / {totalTenants || 0} <br />
            tenants retrieved
          </p>
          <LinearProgress
            variant='determinate'
            value={progress}
            className='mt-2'
            sx={{
              '& .MuiLinearProgress-bar': {
                backgroundColor: progressColor,
              },
            }}
          />
        </>
      )}
    </div>
  )
}

export default TenantLoadingScreen
