export const allGdapRoles = [
  {
    Role: 'Application Administrator',
    Description:
      'Can create and manage all aspects of app registrations and enterprise apps.',
    'Template ID': '9b895d92-2cd3-44c7-9d02-a6ac2d5ea5c3',
    Impact: 'High',
  },
  {
    Role: 'Application Developer',
    Description:
      'Can create application registrations independent of the \u0027Users can register applications\u0027 setting.',
    'Template ID': 'cf1c38e5-3621-4004-a7cb-879624dced7c',
    Impact: 'Medium',
  },
  {
    Role: 'Attack Payload Author',
    Description:
      'Can create attack payloads that an administrator can initiate later.',
    'Template ID': '9c6df0f2-1e7c-4dc3-b195-66dfbd24aa8f',
    Impact: 'Medium',
  },
  {
    Role: 'Attack Simulation Administrator',
    Description:
      'Can create and manage all aspects of attack simulation campaigns.',
    'Template ID': 'c430b396-e693-46cc-96f3-db01bf8bb62a',
    Impact: 'High',
  },
  {
    Role: 'Attribute Assignment Administrator',
    Description:
      'Assign custom security attribute keys and values to supported Microsoft Entra objects.',
    'Template ID': '58a13ea3-c632-46ae-9ee0-9c0d43cd7f3d',
    Impact: 'Low',
  },
  {
    Role: 'Attribute Assignment Reader',
    Description:
      'Read custom security attribute keys and values for supported Microsoft Entra objects.',
    'Template ID': 'ffd52fa5-98dc-465c-991d-fc073eb59f8f',
    Impact: 'Low',
  },
  {
    Role: 'Attribute Definition Administrator',
    Description:
      'Define and manage the definition of custom security attributes.',
    'Template ID': '8424c6f0-a189-499e-bbd0-26c1753c96d4',
    Impact: 'Low',
  },
  {
    Role: 'Attribute Definition Reader',
    Description: 'Read the definition of custom security attributes.',
    'Template ID': '1d336d2c-4ae8-42ef-9711-b3604ce3fc2c',
    Impact: 'Low',
  },
  {
    Role: 'Attribute Log Administrator',
    Description:
      'Read audit logs and configure diagnostic settings for events related to custom security attributes.',
    'Template ID': '5b784334-f94b-471a-a387-e7219fc49ca2',
    Impact: 'Medium',
  },
  {
    Role: 'Attribute Log Reader',
    Description: 'Read audit logs related to custom security attributes.',
    'Template ID': '9c99539d-8186-4804-835f-fd51ef9e2dcd',
    Impact: 'Low',
  },
  {
    Role: 'Authentication Administrator',
    Description:
      'Can access to view, set and reset authentication method information for any non-admin user.',
    'Template ID': 'c4e39bd9-1100-46d3-8c65-fb160da0071f',
    Impact: 'Low',
  },
  {
    Role: 'Authentication Extensibility Administrator',
    Description:
      'Customize sign in and sign up experiences for users by creating and managing custom authentication extensions.',
    'Template ID': '25a516ed-2fa0-40ea-a2d0-12923a21473a',
    Impact: 'Low',
  },
  {
    Role: 'Authentication Policy Administrator',
    Description:
      'Can create and manage the authentication methods policy, tenant-wide MFA settings, password protection policy, and verifiable credentials.',
    'Template ID': '0526716b-113d-4c15-b2c8-68e3c22b9f80',
    Impact: 'Medium',
  },
  {
    Role: 'Azure DevOps Administrator',
    Description: 'Can manage Azure DevOps policies and settings.',
    'Template ID': 'e3973bdf-4987-49ae-837a-ba8e231c7286',
    Impact: 'Low',
  },
  {
    Role: 'Azure Information Protection Administrator',
    Description:
      'Can manage all aspects of the Azure Information Protection product.',
    'Template ID': '7495fdc4-34c4-4d15-a289-98788ce399fd',
    Impact: 'High',
  },
  {
    Role: 'B2C IEF Keyset Administrator',
    Description:
      'Can manage secrets for federation and encryption in the Identity Experience Framework (IEF).',
    'Template ID': 'aaf43236-0c0d-4d5f-883a-6955382ac081',
    Impact: 'Low',
  },
  {
    Role: 'B2C IEF Policy Administrator',
    Description:
      'Can create and manage trust framework policies in the Identity Experience Framework (IEF).',
    'Template ID': '3edaf663-341e-4475-9f94-5c398ef6c070',
    Impact: 'Medium',
  },
  {
    Role: 'Billing Administrator',
    Description:
      'Can perform common billing related tasks like updating payment information.',
    'Template ID': 'b0f54661-2d74-4c50-afa3-1ec803f12efe',
    Impact: 'Low',
  },
  {
    Role: 'Cloud App Security Administrator',
    Description:
      'Can manage all aspects of the Defender for Cloud Apps product.',
    'Template ID': '892c5842-a9a6-463a-8041-72aa08ca3cf6',
    Impact: 'High',
  },
  {
    Role: 'Cloud Application Administrator',
    Description:
      'Can create and manage all aspects of app registrations and enterprise apps except App Proxy.',
    'Template ID': '158c047a-c907-4556-b7ef-446551a6b5f7',
    Impact: 'High',
  },
  {
    Role: 'Cloud Device Administrator',
    Description: 'Limited access to manage devices in Microsoft Entra ID.',
    'Template ID': '7698a772-787b-4ac8-901f-60d6b08affd2',
    Impact: 'Low',
  },
  {
    Role: 'Compliance Administrator',
    Description:
      'Can read and manage compliance configuration and reports in Microsoft Entra ID and Microsoft 365.',
    'Template ID': '17315797-102d-40b4-93e0-432062caca18',
    Impact: 'Low',
  },
  {
    Role: 'Compliance Data Administrator',
    Description: 'Creates and manages compliance content.',
    'Template ID': 'e6d1a23a-da11-4be4-9570-befc86d067a7',
    Impact: 'Low',
  },
  {
    Role: 'Conditional Access Administrator',
    Description: 'Can manage Conditional Access capabilities.',
    'Template ID': 'b1be1c3e-b65d-4f19-8427-f6fa0d97feb9',
    Impact: 'Low',
  },
  {
    Role: 'Customer LockBox Access Approver',
    Description:
      'Can approve Microsoft support requests to access customer organizational data.',
    'Template ID': '5c4f9dcd-47dc-4cf7-8c9a-9e4207cbfc91',
    Impact: 'Low',
  },
  {
    Role: 'Desktop Analytics Administrator',
    Description: 'Can access and manage Desktop management tools and services.',
    'Template ID': '38a96431-2bdf-4b4c-8b6e-5d3d8abac1a4',
    Impact: 'Low',
  },
  {
    Role: 'Directory Readers',
    Description:
      'Can read basic directory information. Commonly used to grant directory read access to applications and guests.',
    'Template ID': '88d8e3e3-8f55-4a1e-953a-9b9898b8876b',
    Impact: 'Low',
  },
  {
    Role: 'Directory Synchronization Accounts',
    Description: 'Only used by Microsoft Entra Connect service.',
    'Template ID': 'd29b2b05-8046-44ba-8758-1e26182fcf32',
    Impact: 'Low',
  },
  {
    Role: 'Directory Writers',
    Description:
      'Can read and write basic directory information. For granting access to applications, not intended for users.',
    'Template ID': '9360feb5-f418-4baa-8175-e2a00bac4301',
    Impact: 'Low',
  },
  {
    Role: 'Domain Name Administrator',
    Description: 'Can manage domain names in cloud and on-premises.',
    'Template ID': '8329153b-31d0-4727-b945-745eb3bc5f31',
    Impact: 'Low',
  },
  {
    Role: 'Dynamics 365 Administrator',
    Description: 'Can manage all aspects of the Dynamics 365 product.',
    'Template ID': '44367163-eba1-44c3-98af-f5787879f96a',
    Impact: 'High',
  },
  {
    Role: 'Dynamics 365 Business Central Administrator',
    Description:
      'Access and perform all administrative tasks on Dynamics 365 Business Central environments.',
    'Template ID': '963797fb-eb3b-4cde-8ce3-5878b3f32a3f',
    Impact: 'Low',
  },
  {
    Role: 'Edge Administrator',
    Description: 'Manage all aspects of Microsoft Edge.',
    'Template ID': '3f1acade-1e04-4fbc-9b69-f0302cd84aef',
    Impact: 'Low',
  },
  {
    Role: 'Exchange Administrator',
    Description: 'Can manage all aspects of the Exchange product.',
    'Template ID': '29232cdf-9323-42fd-ade2-1d097af3e4de',
    Impact: 'High',
  },
  {
    Role: 'Exchange Recipient Administrator',
    Description:
      'Can create or update Exchange Online recipients within the Exchange Online organization.',
    'Template ID': '31392ffb-586c-42d1-9346-e59415a2cc4e',
    Impact: 'Medium',
  },
  {
    Role: 'External ID User Flow Administrator',
    Description: 'Can create and manage all aspects of user flows.',
    'Template ID': '6e591065-9bad-43ed-90f3-e9424366d2f0',
    Impact: 'High',
  },
  {
    Role: 'External ID User Flow Attribute Administrator',
    Description:
      'Can create and manage the attribute schema available to all user flows.',
    'Template ID': '0f971eea-41eb-4569-a71e-57bb8a3eff1e',
    Impact: 'Medium',
  },
  {
    Role: 'External Identity Provider Administrator',
    Description:
      'Can configure identity providers for use in direct federation.',
    'Template ID': 'be2f45a1-457d-42af-a067-6ec1fa63bc45',
    Impact: 'Medium',
  },
  {
    Role: 'Fabric Administrator',
    Description: 'Can manage all aspects of the Fabric and Power BI products.',
    'Template ID': 'a9ea8996-122f-4c74-9520-8edcd192826c',
    Impact: 'High',
  },
  {
    Role: 'Global Administrator',
    Description:
      'Can manage all aspects of Microsoft Entra ID and Microsoft services that use Microsoft Entra identities.',
    'Template ID': '62e90394-69f5-4237-9190-012177145e10',
    Impact: 'High',
  },
  {
    Role: 'Global Reader',
    Description:
      'Can read everything that a Global Administrator can, but not update anything.',
    'Template ID': 'f2ef992c-3afb-46b9-b7cf-a126ee74c451',
    Impact: 'High',
  },
  {
    Role: 'Global Secure Access Administrator',
    Description:
      'Create and manage all aspects of Microsoft Entra Internet Access and Microsoft Entra Private Access, including managing access to public and private endpoints.',
    'Template ID': 'ac434307-12b9-4fa1-a708-88bf58caabc1',
    Impact: 'High',
  },
  {
    Role: 'Groups Administrator',
    Description:
      'Members of this role can create/manage groups, create/manage groups settings like naming and expiration policies, and view groups activity and audit reports.',
    'Template ID': 'fdd7a751-b60b-444a-984c-02652fe8fa1c',
    Impact: 'Medium',
  },
  {
    Role: 'Guest Inviter',
    Description:
      'Can invite guest users independent of the \u0027members can invite guests\u0027 setting.',
    'Template ID': '95e79109-95c0-4d8e-aee3-d01accf2d47b',
    Impact: 'Low',
  },
  {
    Role: 'Helpdesk Administrator',
    Description:
      'Can reset passwords for non-administrators and Helpdesk Administrators.',
    'Template ID': '729827e3-9c14-49f7-bb1b-9608f156bbb8',
    Impact: 'Low',
  },
  {
    Role: 'Hybrid Identity Administrator',
    Description:
      'Manage Active Directory to Microsoft Entra cloud provisioning, Microsoft Entra Connect, pass-through authentication (PTA), password hash synchronization (PHS), seamless single sign-on (seamless SSO), and federation settings. Does not have access to manage Microsoft Entra Connect Health.',
    'Template ID': '8ac3fc64-6eca-42ea-9e69-59f4c7b60eb2',
    Impact: 'Low',
  },
  {
    Role: 'Identity Governance Administrator',
    Description:
      'Manage access using Microsoft Entra ID for identity governance scenarios.',
    'Template ID': '45d8d3c5-c802-45c6-b32a-1d70b5e1e86e',
    Impact: 'Low',
  },
  {
    Role: 'Insights Administrator',
    Description: 'Has administrative access in the Microsoft 365 Insights app.',
    'Template ID': 'eb1f4a8d-243a-41f0-9fbd-c7cdf6c5ef7c',
    Impact: 'Low',
  },
  {
    Role: 'Insights Analyst',
    Description:
      'Access the analytical capabilities in Microsoft Viva Insights and run custom queries.',
    'Template ID': '25df335f-86eb-4119-b717-0ff02de207e9',
    Impact: 'Low',
  },
  {
    Role: 'Insights Business Leader',
    Description:
      'Can view and share dashboards and insights via the Microsoft 365 Insights app.',
    'Template ID': '31e939ad-9672-4796-9c2e-873181342d2d',
    Impact: 'Low',
  },
  {
    Role: 'Intune Administrator',
    Description: 'Can manage all aspects of the Intune product.',
    'Template ID': '3a2c62db-5318-420d-8d74-23affee5d9d5',
    Impact: 'High',
  },
  {
    Role: 'Kaizala Administrator',
    Description: 'Can manage settings for Microsoft Kaizala.',
    'Template ID': '74ef975b-6605-40af-a5d2-b9539d836353',
    Impact: 'Low',
  },
  {
    Role: 'Knowledge Administrator',
    Description:
      'Can configure knowledge, learning, and other intelligent features.',
    'Template ID': 'b5a8dcf3-09d5-43a9-a639-8e29ef291470',
    Impact: 'Medium',
  },
  {
    Role: 'Knowledge Manager',
    Description:
      'Can organize, create, manage, and promote topics and knowledge.',
    'Template ID': '744ec460-397e-42ad-a462-8b3f9747a02c',
    Impact: 'Medium',
  },
  {
    Role: 'License Administrator',
    Description: 'Can manage product licenses on users and groups.',
    'Template ID': '4d6ac14f-3453-41d0-bef9-a3e0c569773a',
    Impact: 'Low',
  },
  {
    Role: 'Lifecycle Workflows Administrator',
    Description:
      'Create and manage all aspects of workflows and tasks associated with Lifecycle Workflows in Microsoft Entra ID.',
    'Template ID': '59d46f88-662b-457b-bceb-5c3809e5908f',
    Impact: 'High',
  },
  {
    Role: 'Message Center Privacy Reader',
    Description:
      'Can read security messages and updates in Office 365 Message Center only.',
    'Template ID': 'ac16e43d-7b2d-40e0-ac05-243ff356ab5b',
    Impact: 'Low',
  },
  {
    Role: 'Message Center Reader',
    Description:
      'Can read messages and updates for their organization in Office 365 Message Center only.',
    'Template ID': '790c1fb9-7f7d-4f88-86a1-ef1f95c05c1b',
    Impact: 'Low',
  },
  {
    Role: 'Microsoft 365 Migration Administrator',
    Description:
      'Perform all migration functionality to migrate content to Microsoft 365 using Migration Manager.',
    'Template ID': '8c8b803f-96e1-4129-9349-20738d9f9652',
    Impact: 'Low',
  },
  {
    Role: 'Microsoft Entra Joined Device Local Administrator',
    Description:
      'Users assigned to this role are added to the local administrators group on Microsoft Entra joined devices.',
    'Template ID': '9f06204d-73c1-4d4c-880a-6edb90606fd8',
    Impact: 'Medium',
  },
  {
    Role: 'Microsoft Hardware Warranty Administrator',
    Description:
      'Create and manage all aspects warranty claims and entitlements for Microsoft manufactured hardware, like Surface and HoloLens.',
    'Template ID': '1501b917-7653-4ff9-a4b5-203eaf33784f',
    Impact: 'High',
  },
  {
    Role: 'Microsoft Hardware Warranty Specialist',
    Description:
      'Create and read warranty claims for Microsoft manufactured hardware, like Surface and HoloLens.',
    'Template ID': '281fe777-fb20-4fbb-b7a3-ccebce5b0d96',
    Impact: 'Low',
  },
  {
    Role: 'Modern Commerce Administrator',
    Description:
      'Can manage commercial purchases for a company, department or team.',
    'Template ID': 'd24aef57-1500-4070-84db-2666f29cf966',
    Impact: 'Low',
  },
  {
    Role: 'Network Administrator',
    Description:
      'Can manage network locations and review enterprise network design insights for Microsoft 365 Software as a Service applications.',
    'Template ID': 'd37c8bed-0711-4417-ba38-b4abe66ce4c2',
    Impact: 'Low',
  },
  {
    Role: 'Office Apps Administrator',
    Description:
      'Can manage Office apps cloud services, including policy and settings management, and manage the ability to select, unselect and publish \u0027what\u0027s new\u0027 feature content to end-user\u0027s devices.',
    'Template ID': '2b745bdf-0803-4d80-aa65-822c4493daac',
    Impact: 'Low',
  },
  {
    Role: 'Organizational Branding Administrator',
    Description: 'Manage all aspects of organizational branding in a tenant.',
    'Template ID': '92ed04bf-c94a-4b82-9729-b799a7a4c178',
    Impact: 'Low',
  },
  {
    Role: 'Organizational Messages Approver',
    Description:
      'Review, approve, or reject new organizational messages for delivery in the Microsoft 365 admin center before they are sent to users.',
    'Template ID': 'e48398e2-f4bb-4074-8f31-4586725e205b',
    Impact: 'Low',
  },
  {
    Role: 'Organizational Messages Writer',
    Description:
      'Write, publish, manage, and review the organizational messages for end-users through Microsoft product surfaces.',
    'Template ID': '507f53e4-4e52-4077-abd3-d2e1558b6ea2',
    Impact: 'Low',
  },
  {
    Role: 'Partner Tier1 Support',
    Description: 'Do not use - not intended for general use.',
    'Template ID': '4ba39ca4-527c-499a-b93d-d9b492c50246',
    Impact: 'Low',
  },
  {
    Role: 'Partner Tier2 Support',
    Description: 'Do not use - not intended for general use.',
    'Template ID': 'e00e864a-17c5-4a4b-9c06-f5b95a8d5bd8',
    Impact: 'Low',
  },
  {
    Role: 'Password Administrator',
    Description:
      'Can reset passwords for non-administrators and Password Administrators.',
    'Template ID': '966707d0-3269-4727-9be2-8c3a10f19b9d',
    Impact: 'Low',
  },
  {
    Role: 'Permissions Management Administrator',
    Description:
      'Manage all aspects of Microsoft Entra Permissions Management.',
    'Template ID': 'af78dc32-cf4d-46f9-ba4e-4428526346b5',
    Impact: 'Low',
  },
  {
    Role: 'Power Platform Administrator',
    Description:
      'Can create and manage all aspects of Microsoft Dynamics 365, Power Apps and Power Automate.',
    'Template ID': '11648597-926c-4cf3-9c36-bcebb0ba8dcc',
    Impact: 'High',
  },
  {
    Role: 'Printer Administrator',
    Description: 'Can manage all aspects of printers and printer connectors.',
    'Template ID': '644ef478-e28f-4e28-b9dc-3fdde9aa0b1f',
    Impact: 'High',
  },
  {
    Role: 'Printer Technician',
    Description:
      'Can register and unregister printers and update printer status.',
    'Template ID': 'e8cef6f1-e4bd-4ea8-bc07-4b8d950f4477',
    Impact: 'Low',
  },
  {
    Role: 'Privileged Authentication Administrator',
    Description:
      'Can access to view, set and reset authentication method information for any user (admin or non-admin).',
    'Template ID': '7be44c8a-adaf-4e2a-84d6-ab2649e08a13',
    Impact: 'Low',
  },
  {
    Role: 'Privileged Role Administrator',
    Description:
      'Can manage role assignments in Microsoft Entra ID, and all aspects of Privileged Identity Management.',
    'Template ID': 'e8611ab8-c189-46e8-94e1-60213ab1f814',
    Impact: 'Medium',
  },
  {
    Role: 'Reports Reader',
    Description: 'Can read sign-in and audit reports.',
    'Template ID': '4a5d8f65-41da-4de4-8968-e035b65339cf',
    Impact: 'Low',
  },
  {
    Role: 'Search Administrator',
    Description:
      'Can create and manage all aspects of Microsoft Search settings.',
    'Template ID': '0964bb5e-9bdb-4d7b-ac29-58e794862a40',
    Impact: 'High',
  },
  {
    Role: 'Search Editor',
    Description:
      'Can create and manage the editorial content such as bookmarks, Q and As, locations, floorplan.',
    'Template ID': '8835291a-918c-4fd7-a9ce-faa49f0cf7d9',
    Impact: 'Medium',
  },
  {
    Role: 'Security Administrator',
    Description:
      'Can read security information and reports, and manage configuration in Microsoft Entra ID and Office 365.',
    'Template ID': '194ae4cb-b126-40b2-bd5b-6091b380977d',
    Impact: 'Low',
  },
  {
    Role: 'Security Operator',
    Description: 'Creates and manages security events.',
    'Template ID': '5f2222b1-57c3-48ba-8ad5-d4759f1fde6f',
    Impact: 'Low',
  },
  {
    Role: 'Security Reader',
    Description:
      'Can read security information and reports in Microsoft Entra ID and Office 365.',
    'Template ID': '5d6b6bb7-de71-4623-b4af-96380a352509',
    Impact: 'Low',
  },
  {
    Role: 'Service Support Administrator',
    Description:
      'Can read service health information and manage support tickets.',
    'Template ID': 'f023fd81-a637-4b56-95fd-791ac0226033',
    Impact: 'Low',
  },
  {
    Role: 'SharePoint Administrator',
    Description: 'Can manage all aspects of the SharePoint service.',
    'Template ID': 'f28a1f50-f6e7-4571-818b-6a12f2af6b6c',
    Impact: 'High',
  },
  {
    Role: 'SharePoint Embedded Administrator',
    Description: 'Manage all aspects of SharePoint Embedded containers.',
    'Template ID': '1a7d78b6-429f-476b-b8eb-35fb715fffd4',
    Impact: 'Low',
  },
  {
    Role: 'Skype for Business Administrator',
    Description: 'Can manage all aspects of the Skype for Business product.',
    'Template ID': '75941009-915a-4869-abe7-691bff18279e',
    Impact: 'High',
  },
  {
    Role: 'Teams Administrator',
    Description: 'Can manage the Microsoft Teams service.',
    'Template ID': '69091246-20e8-4a56-aa4d-066075b2a7a8',
    Impact: 'Low',
  },
  {
    Role: 'Teams Communications Administrator',
    Description:
      'Can manage calling and meetings features within the Microsoft Teams service.',
    'Template ID': 'baf37b3a-610e-45da-9e62-d9d1e5e8914b',
    Impact: 'Low',
  },
  {
    Role: 'Teams Communications Support Engineer',
    Description:
      'Can troubleshoot communications issues within Teams using advanced tools.',
    'Template ID': 'f70938a0-fc10-4177-9e90-2178f8765737',
    Impact: 'Low',
  },
  {
    Role: 'Teams Communications Support Specialist',
    Description:
      'Can troubleshoot communications issues within Teams using basic tools.',
    'Template ID': 'fcf91098-03e3-41a9-b5ba-6f0ec8188a12',
    Impact: 'Low',
  },
  {
    Role: 'Teams Devices Administrator',
    Description:
      'Can perform management related tasks on Teams certified devices.',
    'Template ID': '3d762c5a-1b6c-493f-843e-55a3b42923d4',
    Impact: 'Low',
  },
  {
    Role: 'Teams Telephony Administrator',
    Description:
      'Manage voice and telephony features and troubleshoot communication issues within the Microsoft Teams service.',
    'Template ID': 'aa38014f-0993-46e9-9b45-30501a20909d',
    Impact: 'Low',
  },
  {
    Role: 'Tenant Creator',
    Description: 'Create new Microsoft Entra or Azure AD B2C tenants.',
    'Template ID': '112ca1a2-15ad-4102-995e-45b0bc479a6a',
    Impact: 'Low',
  },
  {
    Role: 'Usage Summary Reports Reader',
    Description:
      'Read Usage reports and Adoption Score, but can\u0027t access user details.',
    'Template ID': '75934031-6c7e-415a-99d7-48dbd49e875e',
    Impact: 'Low',
  },
  {
    Role: 'User Administrator',
    Description:
      'Can manage all aspects of users and groups, including resetting passwords for limited admins.',
    'Template ID': 'fe930be7-5e62-47db-91af-98c3a49a38b1',
    Impact: 'High',
  },
  {
    Role: 'User Experience Success Manager',
    Description:
      'View product feedback, survey results, and reports to find training and communication opportunities.',
    'Template ID': '27460883-1df1-4691-b032-3b79643e5e63',
    Impact: 'Low',
  },
  {
    Role: 'Virtual Visits Administrator',
    Description:
      'Manage and share Virtual Visits information and metrics from admin centers or the Virtual Visits app.',
    'Template ID': 'e300d9e7-4a2b-4295-9eff-f1c78b36cc98',
    Impact: 'Low',
  },
  {
    Role: 'Viva Goals Administrator',
    Description: 'Manage and configure all aspects of Microsoft Viva Goals.',
    'Template ID': '92b086b3-e367-4ef2-b869-1de128fb986e',
    Impact: 'Medium',
  },
  {
    Role: 'Viva Pulse Administrator',
    Description: 'Can manage all settings for Microsoft Viva Pulse app.',
    'Template ID': '87761b17-1ed2-4af3-9acd-92a150038160',
    Impact: 'Low',
  },
  {
    Role: 'Windows 365 Administrator',
    Description: 'Can provision and manage all aspects of Cloud PCs.',
    'Template ID': '11451d60-acb2-45eb-a7d6-43d0f0125c13',
    Impact: 'High',
  },
  {
    Role: 'Windows Update Deployment Administrator',
    Description:
      'Can create and manage all aspects of Windows Update deployments through the Windows Update for Business deployment service.',
    'Template ID': '32696413-001a-46ae-978c-ce0f6b3620d2',
    Impact: 'High',
  },
  {
    Role: 'Yammer Administrator',
    Description: 'Manage all aspects of the Yammer service.',
    'Template ID': '810a2642-a034-447f-a5e8-41beaa378541',
    Impact: 'Low',
  },
]
