import { PieChart, Pie, Cell, Tooltip, Label, Legend } from 'recharts'

const GenericPieChart = ({
  colors,
  data,
  dataLabel,
  displayValue,
  chartHeight,
  chartWidth,
  isPercentage,
  showLegend,
  realTheme,
}) => {
  const renderCustomizedLabel = ({ viewBox }) => {
    const { cx, cy } = viewBox

    // Choose label colors based on the theme
    const labelColor = realTheme === 'dark' ? '#eee' : '#102550'
    const secondaryLabelColor = realTheme === 'dark' ? '#eee' : '#6B7280'

    return (
      <g>
        <text
          x={cx}
          y={cy + 8}
          textAnchor='middle'
          fontSize='46px'
          fontWeight='bold'
          fill={labelColor}
        >
          {displayValue}
          {isPercentage && '%'}
        </text>
        <text
          x={cx}
          y={cy + 30}
          textAnchor='middle'
          fontSize='16px'
          fill={secondaryLabelColor}
        >
          {dataLabel}
        </text>
      </g>
    )
  }

  const renderLegendText = (value, entry) => {
    const { value: count } = entry.payload
    return `${value}: ${count}`
  }

  return (
    <div className='flex items-center justify-center dark:bg-slate-900'>
      <PieChart width={chartWidth || 320} height={chartHeight || 260}>
        <Pie
          data={data}
          dataKey='value'
          nameKey='name'
          cx='50%'
          cy='50%'
          innerRadius={96}
          outerRadius={124}
          paddingAngle={2}
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
          ))}
          <Label content={renderCustomizedLabel} position='center' />
        </Pie>
        <Tooltip />
        {showLegend ? (
          <Legend
            formatter={renderLegendText}
            align='left'
            wrapperStyle={{ bottom: '-5px' }}
          />
        ) : null}
      </PieChart>
    </div>
  )
}

export default GenericPieChart
