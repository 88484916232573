/*eslint-disable*/

const CalculateSecureScore = ({ allSecureScores, selectedCustomer }) => {
  let scoreOverDays = []
  let lowestDay = 100
  allSecureScores.map(secureScore => {
    if (selectedCustomer.id === secureScore.tenantId) {
      let currentDate = new Date()
      let dateString = secureScore.createdDateTime.split('T')[0]
      let date = new Date(dateString)
      let daysAgo = Math.floor((currentDate - date) / (60 * 60 * 1000 * 24))

      if (currentDate - date > 0) {
        scoreOverDays.push({
          score:
            Math.floor(
              (secureScore.currentScore / secureScore.maxScore) * 1000
            ) / 10,
          day: daysAgo,
        })
      }
    }
  })

  return scoreOverDays
}

export default CalculateSecureScore
