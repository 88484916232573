import { useSnackbar } from 'notistack'
import { minimumRoles } from '../config/OnboardingConfig'
import { getFromIndexedDB, removeFromIndexedDB } from './idbUtils'

const isTokenExpired = async () => {
  const onboardingState = await getFromIndexedDB('onboardingState')

  if (!onboardingState) {
    console.error('No onboardingState found in storage.')
    return { accessTokenExpired: true, graphTokenExpired: true } // Default to expired
  }

  const { accessToken, graphToken } = onboardingState

  const checkExpiration = token => {
    try {
      const payloadBase64 = token.split('.')[1] // Extract payload part of JWT
      const decodedPayload = JSON.parse(atob(payloadBase64)) // Decode Base64
      const currentTime = Math.floor(Date.now() / 1000) // Current time in seconds

      return decodedPayload.exp < currentTime // Return true if expired
    } catch (error) {
      console.error('Failed to parse token:', error)
      return true // Consider token expired if parsing fails
    }
  }

  const accessTokenExpired = checkExpiration(accessToken)
  const graphTokenExpired = checkExpiration(graphToken)

  return {
    accessTokenExpired,
    graphTokenExpired,
  }
}

export const useTokenExpiryCheck = () => {
  const { enqueueSnackbar } = useSnackbar()

  const tokenExpiry = async () => {
    const { accessTokenExpired } = await isTokenExpired()

    if (accessTokenExpired) {
      console.warn('Access Token is expired!')
      await removeFromIndexedDB('onboardingState')

      enqueueSnackbar('Your session has expired. Please log in again.', {
        variant: 'error',
      })

      setTimeout(() => {
        window.location.reload()
      }, 1500) // Allow the snackbar to appear before reload

      return true // Indicate token was expired
    }

    return false // Token is still valid
  }

  return tokenExpiry
}

export const clearOnboardingIfTokenExpired = () => {
  if (isTokenExpired()) {
    console.log('Token has expired. Clearing local onboarding state...')
  } else {
    console.log('Token is still valid. Local onboarding state not cleared.')
  }
}

export const clearLocalOnboardingState = async () => {
  await removeFromIndexedDB('onboardingState')
  await removeFromIndexedDB('userProfile')
  await removeFromIndexedDB('firstLogin')
  window.location.reload()
}

export const calculateUnconsumedLicenses = licenses => {
  if (!licenses || licenses.length === 0) {
    return 0 // No licenses available
  }

  let totalConsumedUnits = 0
  let totalActiveUnits = 0

  licenses.forEach(license => {
    const licenseName = license.productSku.name
    if (
      licenseName.includes('Microsoft 365 Business') ||
      licenseName.includes('Microsoft 365 E') ||
      licenseName.includes('Microsoft 365 F')
    ) {
      // This is a primary license
      totalConsumedUnits += license.consumedUnits
      totalActiveUnits += license.activeUnits
    }
  })

  const difference = totalActiveUnits - totalConsumedUnits
  return difference < 0 ? 0 : difference
}

export function mergeGroupsIntoCustomers(customers, securityGroups) {
  return customers.map(customer => {
    // Build assignedGroups from the customers "assignments"
    const assignedGroups = (customer.assignments || [])
      .filter(
        assignment =>
          assignment.accessContainer.accessContainerType === 'securityGroup'
      )
      .map(assignment => {
        // Find matching security group data
        const groupId = assignment.accessContainer.accessContainerId
        const matchingGroup = securityGroups.find(g => g.details.id === groupId)

        // Fallback if none found
        const groupName = matchingGroup
          ? matchingGroup.details.displayName
          : 'Unknown Group'
        const groupMembers = matchingGroup ? matchingGroup.members : []

        // Collect roles from the assignment
        const rolesFromThisGroup =
          assignment.accessDetails?.unifiedRoles?.map(
            roleObj => roleObj.roleDefinitionId
          ) || []

        return {
          groupId,
          groupName,
          groupMembers,
          roles: rolesFromThisGroup,
        }
      })

    // Merged set of all roleDefinitionIds from assignedGroups
    const groupRolesSet = new Set()
    assignedGroups.forEach(grp => {
      grp.roles?.forEach(roleId => groupRolesSet.add(roleId))
    })

    // Compare to your minimumRoles
    const missingRoles = []
    minimumRoles.forEach(r => {
      if (!groupRolesSet.has(r.id)) {
        missingRoles.push(r.displayName)
      }
    })

    // Decide the status
    let groupStatus = 'No Group Roles'
    if (assignedGroups.length === 0) {
      groupStatus = 'No Group Roles'
    } else if (missingRoles.length === 0) {
      groupStatus = 'Inforcer Ready'
    } else {
      groupStatus = `Missing ${missingRoles.length} Roles`
    }

    return {
      ...customer,
      assignedGroups,
      groupRoles: Array.from(groupRolesSet),
      groupMissingRoles: missingRoles,
      groupStatus,
    }
  })
}

export const getSecurityGroupStatus = customer => {
  // If no assignedGroups or empty => "No Group Relationship"
  if (!customer.assignedGroups || customer.assignedGroups.length === 0) {
    return {
      status: 'No Group Relationship',
      missingRoles: [],
    }
  }

  // Declare assignedGroups
  const assignedGroups = customer.assignedGroups || []

  // Gather all roleDefinitionIds from assignedGroups
  const groupRoles = new Set()
  assignedGroups.forEach(grp => {
    if (grp.roles) {
      grp.roles.forEach(r => groupRoles.add(r))
    }
  })

  // Compare groupRoles to minimumRoles
  const missing = []
  minimumRoles.forEach(({ id, displayName }) => {
    if (!groupRoles.has(id)) {
      missing.push(displayName)
    }
  })

  if (missing.length === 0) {
    return {
      status: 'Group Ready',
      missingRoles: [],
    }
  } else {
    return {
      status: `Missing ${missing.length} Roles`,
      missingRoles: missing,
    }
  }
}

// Helper function to flatten objects for CSV export
const flattenObject = (obj, parent = '', res = {}) => {
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const propName = parent ? `${parent}.${key}` : key

      // Handle arrays of objects like unifiedRoles
      if (Array.isArray(obj[key])) {
        if (obj[key].length > 0 && typeof obj[key][0] === 'object') {
          // If the array contains objects, extract the roleDefinitionId from each object
          res[propName] = obj[key]
            .map(item => item.roleDefinitionId || JSON.stringify(item))
            .join(', ')
        } else {
          // If the array contains non-object items, join them as a comma-separated string
          res[propName] = obj[key].join(', ')
        }
      } else if (typeof obj[key] === 'object' && obj[key] !== null) {
        flattenObject(obj[key], propName, res) // Recursively flatten nested objects
      } else {
        res[propName] = obj[key] // Assign other values as-is
      }
    }
  }
  return res
}
