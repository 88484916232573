const FlexibleButton = ({ variant = 'primary', children, onClick }) => {
  let buttonClasses

  switch (variant) {
    case 'secondary':
      buttonClasses =
        'border-inforcer-navy bg-inforcer-navy text-white hover:text-inforcer-navy active:text-inforcer-navy dark:text-slate-900 dark:border-gray-200 dark:bg-gray-200 dark:hover:bg-transparent dark:hover:text-gray-200'
      break
    case 'primary':
    default:
      buttonClasses =
        'border-inforcer-cyan bg-inforcer-cyan text-white hover:text-inforcer-cyan active:text-inforcer-cyan dark:bg-dark-mode-primary dark:border-dark-mode-primary dark:hover:text-gray-200 dark:hover:border-gray-200 dark:active:text-dark-mode-primary'
      break
  }

  return (
    <button
      onClick={onClick}
      type='button'
      className={`flex items-center rounded border text-sm px-2 py-2 hover:bg-transparent dark:hover:bg-transparent focus:outline-none focus:ring cursor-pointer ${buttonClasses}`}
    >
      {children}
    </button>
  )
}

export default FlexibleButton
