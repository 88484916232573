import { LogLevel } from '@azure/msal-browser'
import { allGdapRoles } from './EntraIDRoles'

export const parallelOnboardingChunkMaxSize = 50

export const commonLoginAuthority = `https://login.microsoftonline.com/common`

export const cspClientId = '3559012a-edd4-43a8-adf7-b35b48404a3d'

export const tenantClientId = '5bc01670-ad55-4428-b57a-db56700ecc4f'

export const delegatedAdminAuthScopes = [
  'DelegatedAdminRelationship.ReadWrite.All',
  'Domain.Read.All',
]

export const allEntraIDRoles = allGdapRoles

export const loggerOptions = {
  loggerCallback: (level, message, containsPii) => {
    if (containsPii) {
      return
    }
    switch (level) {
      case LogLevel.Error:
        console.error(message)
        return
      case LogLevel.Info:
        console.info(message)
        return
      case LogLevel.Verbose:
        console.debug(message)
        return
      case LogLevel.Warning:
        console.warn(message)
        break
      default:
        break
    }
  },
}

// MSAL configuration
export const configuration = {
  auth: {
    clientId: cspClientId,
    authority: `https://login.microsoftonline.com/common`,
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false,
  },
  system: {
    loggerOptions,
  },
}

export const globalAdminRole = {
  displayName: 'Global Administrator',
  id: '62e90394-69f5-4237-9190-012177145e10',
}

export const minimumLicensingRoles = [
  {
    displayName: 'Global Reader',
    id: 'f2ef992c-3afb-46b9-b7cf-a126ee74c451',
  },
  {
    displayName: 'Directory Reader',
    id: '88d8e3e3-8f55-4a1e-953a-9b9898b8876b',
  },
  {
    displayName: 'Directory Writer',
    id: '9360feb5-f418-4baa-8175-e2a00bac4301',
  },
]

export const minimumRoles = [
  {
    displayName: 'Security Administrator',
    id: '194ae4cb-b126-40b2-bd5b-6091b380977d',
  },
  {
    displayName: 'Directory Writers',
    id: '9360feb5-f418-4baa-8175-e2a00bac4301',
  },
  {
    displayName: 'Application Administrator',
    id: '9b895d92-2cd3-44c7-9d02-a6ac2d5ea5c3',
  },
  {
    displayName: 'User Administrator',
    id: 'fe930be7-5e62-47db-91af-98c3a49a38b1',
  },
  {
    displayName: 'Privileged Role Administrator',
    id: 'e8611ab8-c189-46e8-94e1-60213ab1f814',
  },
  {
    displayName: 'Helpdesk Administrator',
    id: '729827e3-9c14-49f7-bb1b-9608f156bbb8',
  },
  {
    displayName: 'Intune Administrator',
    id: '3a2c62db-5318-420d-8d74-23affee5d9d5',
  },
  {
    displayName: 'SharePoint Administrator',
    id: 'f28a1f50-f6e7-4571-818b-6a12f2af6b6c',
  },
  {
    displayName: 'Teams Administrator',
    id: '69091246-20e8-4a56-aa4d-066075b2a7a8',
  },
  {
    displayName: 'Groups Administrator',
    id: 'fdd7a751-b60b-444a-984c-02652fe8fa1c',
  },
  {
    displayName: 'Exchange Administrator',
    id: '29232cdf-9323-42fd-ade2-1d097af3e4de',
  },
]

// MSAL scopes
export const cspAuthScopes = [
  'https://api.partnercenter.microsoft.com/user_impersonation',
  'Application.ReadWrite.All',
  'User.Read',
  'RoleManagement.ReadWrite.Directory',
]

export const cspGraphAuthScopes = [
  'Application.ReadWrite.All',
  'RoleManagement.ReadWrite.Directory',
]

export const tenantAuthScopes = [
  'Application.ReadWrite.All',
  'AppRoleAssignment.ReadWrite.All',
  'RoleManagement.ReadWrite.Directory',
]

// App Registration Permissions
export const tenantGraphPermissions = ''

export const tenantDefenderPermissions = ''

export const exchangePermissions = ''
