import { library } from '@fortawesome/fontawesome-svg-core'
import { faMicrosoft, faFontAwesome } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Alert from './Alert/Alert'
import inforcerLogo from '../assets/inforcer-logo-rubik-new.svg'
import inforcerLogoDark from '../assets/inforcer-white-blue.svg'
import lightGradient from '../assets/light-gradient.png'
import darkGradient from '../assets/dark-gradient.jpg'

library.add(faFontAwesome, faMicrosoft)

const MSLogin = ({ onClick, error, realTheme }) => (
  <div
    className='w-screen bg-white dark:bg-slate-900 dark:text-white'
    style={{
      backgroundImage: `url(${realTheme === 'light' ? lightGradient : darkGradient})`,
      backgroundPosition: 'top',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
    }}
  >
    <div className='flex flex-col items-center justify-center px-8 py-8 mx-auto h-screen lg:py-0'>
      <img
        className='h-12 mb-6 block'
        src={realTheme === 'light' ? inforcerLogo : inforcerLogoDark}
        alt='logo'
      />
      <p className='flex flex-col items-center mb-10 text-2xl font-semibold text-black dark:text-white'>
        Partner Center Companion Tool
      </p>
      <div className='p-2 w-full items-center rounded-lg shadow border md:mt-0 sm:max-w-lg border-slate-700 bg-inforcer-deep-blue'>
        <div className='items-center'>
          {error && (
            <Alert type='error' title='Error' styles='mx-4 mt-2 p-2'>
              {' '}
              <span>
                {error.message.props.children ||
                  error.message ||
                  'An unknown error occurred'}
              </span>
            </Alert>
          )}
          <div className='flex flex-col items-center py-3'>
            <div className=' px-2 mx-4 mb-4 items-center border-slate-700 bg-slate-900/80 rounded-lg border md:mt-0 sm:max-w-lg dark:bg-inforcer-dark-grey'>
              <p className='flex flex-col mt-2 mb-1 items-center text-2xl font-semibold text-white'>
                Notice
              </p>

              <p className='mb-4 text-center text-md text-white'>
                You must be a Microsoft Partner Center{' '}
                <span className='font-bold'>Admin Agent</span> to use this tool.{' '}
                <a
                  target='_blank'
                  rel='noreferrer'
                  href='https://docs.inforcer.com/en/articles/10760776-how-to-sign-in'
                  className='font-medium underline hover:text-gray-50 text-gray-300'
                >
                  Find out more here
                </a>
                .
              </p>
            </div>
            <button
              type='button'
              className='mt-4 mx-auto items-center gap-2 rounded border border-inforcer-cyan bg-inforcer-cyan px-20 py-3 text-white hover:bg-transparent hover:text-white focus:outline-none focus:ring active:text-inforcer-cyan dark:bg-dark-mode-primary dark:border-dark-mode-primary dark:hover:text-white dark:hover:bg-transparent dark:active:text-dark-mode-primary'
              onClick={onClick}
            >
              <span className='text-lg inline-flex'>
                <FontAwesomeIcon
                  className='text-2xl px-2'
                  icon='fa-brands fa-microsoft'
                />
                Sign in with Partner Center{' '}
              </span>
            </button>
          </div>
          <p className='flex flex-col mb-4 items-center text-sm font-light text-gray-400'>
            <a
              target='_blank'
              rel='noreferrer'
              href='https://25566532.fs1.hubspotusercontent-eu1.net/hubfs/25566532/Inforcer%20Site%20Terms%20(8%20Jan%202025)%20(002)%201.pdf'
              className='font-medium hover:underline text-primary-500'
            >
              Terms & Conditions
            </a>
          </p>
        </div>
      </div>
    </div>
  </div>
)

export default MSLogin
