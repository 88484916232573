import { useState } from 'react'
import { getFromIndexedDB, saveToIndexedDB } from '../utils/idbUtils'

const useAutoRenew = ({ getAutoRenewActionType, selectedListItems }) => {
  const [autoRenewLoading, setAutoRenewLoading] = useState(false)
  const [autoRenewResponses, setAutoRenewResponses] = useState(null)

  // Batching
  const createBatches = (items, batchSize) => {
    const batches = []
    for (let i = 0; i < items.length; i += batchSize) {
      batches.push(items.slice(i, i + batchSize))
    }
    return batches
  }

  const getAccessToken = async () => {
    try {
      const onboardingState = await getFromIndexedDB('onboardingState')

      if (!onboardingState || !onboardingState.graphToken) {
        throw new Error('No valid access token found in IndexedDB')
      }

      return onboardingState.graphToken
    } catch (error) {
      console.error('Failed to retrieve access token:', error)
      return null
    }
  }

  const formatETag = eTag => {
    if (!eTag) return null
    // eslint-disable-next-line no-useless-escape
    return eTag.replace(/^W\\/, 'W/').replace(/\\\"/g, '"')
  }

  const fetchETag = async relationshipId => {
    try {
      const token = await getAccessToken()
      if (!token) {
        throw new Error('Access token is missing')
      }

      const url = `https://graph.microsoft.com/beta/tenantRelationships/delegatedAdminRelationships/${relationshipId}`

      const response = await fetch(url, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })

      if (!response.ok) {
        const errorText = await response.text()
        throw new Error(
          `Failed to fetch ETag for ${relationshipId}. Response: ${response.status} - ${errorText}`
        )
      }

      const data = await response.json()
      return data['@odata.etag']
    } catch (error) {
      console.error(`Failed to fetch ETag:`, error)
      return null
    }
  }

  const toggleAutoRenew = async (relationshipId, eTag, actionType) => {
    try {
      const token = await getAccessToken()
      if (!token) {
        throw new Error('Access token is missing')
      }

      const body =
        actionType === 'enable'
          ? { autoExtendDuration: 'P180D' }
          : { autoExtendDuration: 'PT0S' }

      const response = await fetch(
        `https://graph.microsoft.com/beta/tenantRelationships/delegatedAdminRelationships/${relationshipId}`,
        {
          method: 'PATCH',
          headers: {
            Authorization: `Bearer ${token}`,
            'If-Match': eTag,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(body),
        }
      )

      const responseData = await response.json()

      return { ok: response.ok, relationshipId, responseData }
    } catch (error) {
      console.error(
        `Error ${actionType}ing Auto-Renew for ${relationshipId}:`,
        error
      )
      return { ok: false, relationshipId, error: error.message }
    }
  }

  const handleEnableAutoRenew = async () => {
    try {
      if (!selectedListItems || selectedListItems.length === 0) return

      setAutoRenewLoading(true)
      const results = []
      const batchSize = 15
      const actionType = getAutoRenewActionType()

      // Split selectedListItems into batches
      const batches = createBatches(selectedListItems, batchSize)

      // Process each batch
      for (const batch of batches) {
        const batchRequests = await Promise.all(
          batch.map(async item => {
            if (
              item.unifiedRoles?.some(
                role =>
                  role.roleDefinitionId ===
                  '62e90394-69f5-4237-9190-012177145e10'
              )
            ) {
              return {
                relationshipId: item.relationshipId,
                ok: false,
                message: 'Skipped due to Global Admin role',
              }
            }

            const eTag = await fetchETag(item.relationshipId)
            if (!eTag) {
              return {
                relationshipId: item.relationshipId,
                ok: false,
                message: 'No ETag found',
              }
            }

            const formattedETag = formatETag(eTag)
            return toggleAutoRenew(
              item.relationshipId,
              formattedETag,
              actionType
            )
          })
        )

        results.push(...batchRequests)
      }

      setAutoRenewLoading(false)
      setAutoRenewResponses(results)

      const onboardingState = (await getFromIndexedDB('onboardingState')) || {}

      onboardingState.customers = onboardingState.customers.map(customer => {
        const matchingResponse = results.find(
          res =>
            res.ok &&
            res.responseData?.customer?.tenantId ===
              customer.companyProfile.tenantId
        )

        if (matchingResponse?.responseData?.autoExtendDuration) {
          return {
            ...customer,
            autoExtendDuration:
              matchingResponse.responseData.autoExtendDuration,
          }
        }
        return customer
      })

      await saveToIndexedDB('onboardingState', onboardingState)
    } catch (error) {
      console.error('Failed to enable Auto-Renew:', error)
      setAutoRenewLoading(false)
    }
  }

  return {
    autoRenewLoading,
    setAutoRenewLoading,
    createBatches,
    autoRenewResponses,
    setAutoRenewResponses,
    handleEnableAutoRenew,
  }
}

export default useAutoRenew
