import Alert from './Alert/Alert'
import AutoRenewModalSkeleton from './Skeletons/AutoRenewModalSkeleton'
import { useTokenExpiryCheck } from '../utils/utils'

const RemoveGADialog = ({
  selectedListItems,
  setModalOpen,
  handleRemoveGlobalAdmin,
  setRemoveGAResponses,
  closeModalRefreshTenants,
  removeGALoading,
  removeGAResponses,
  pollResult,
}) => {
  const tenantNames =
    selectedListItems?.map(item => item.companyProfile?.companyName) || []

  const expiryCheck = useTokenExpiryCheck()

  const clickRemoveGlobalAdmin = async () => {
    if (await expiryCheck()) {
      return
    }

    handleRemoveGlobalAdmin(selectedListItems)
  }

  const closeModal = () => {
    setRemoveGAResponses(null)
    setModalOpen(false)
  }

  return (
    <>
      <h3 className='text-3xl font-bold text-inforcer-navy mb-4'>
        Remove Global Admin
      </h3>

      {!removeGALoading && removeGAResponses === null && (
        <>
          <p className='mb-2'>Selected tenants:</p>
          <ul className='mb-4'>
            {tenantNames.length > 0 ? (
              tenantNames.map((name, index) => <li key={index}>{name}</li>)
            ) : (
              <p>No tenants selected.</p>
            )}
          </ul>
          <div className='dialog-buttons mt-4'>
            <button
              onClick={clickRemoveGlobalAdmin}
              className='rounded border text-sm border-inforcer-cyan bg-inforcer-cyan px-2 py-2 text-white hover:bg-transparent hover:text-inforcer-cyan focus:outline-none focus:ring active:text-inforcer-cyan'
            >
              Submit
            </button>
            <button
              onClick={closeModal}
              className='ml-4 rounded border text-sm border-inforcer-navy bg-inforcer-navy px-2 py-2 text-white hover:bg-transparent hover:text-inforcer-navy focus:outline-none focus:ring active:text-inforcer-navy'
            >
              Close Modal
            </button>
          </div>
        </>
      )}

      {removeGALoading && <AutoRenewModalSkeleton />}

      {!removeGALoading && removeGAResponses && (
        <>
          <ul>
            {removeGAResponses.map((response, index) => (
              <li key={index}>
                {response.ok ? (
                  <Alert type='success'>
                    Successfully removed Global Admin for:{' '}
                    <b>
                      {pollResult?.data?.customer?.displayName ||
                        'Selected tenant(s)'}
                    </b>
                  </Alert>
                ) : (
                  <Alert type='error'>
                    <b>Error</b>
                    <br />
                    {`Failed for: ${pollResult?.data?.id || 'Selected tenant(s)'}`}{' '}
                    <br />{' '}
                  </Alert>
                )}
              </li>
            ))}
          </ul>
          <div className='dialog-buttons mt-4'>
            <button
              onClick={closeModalRefreshTenants}
              className='rounded border text-sm border-inforcer-navy bg-inforcer-navy px-2 py-2 text-white hover:bg-transparent hover:text-inforcer-navy focus:outline-none focus:ring active:text-inforcer-navy'
            >
              Close Modal
            </button>
          </div>
        </>
      )}
    </>
  )
}

export default RemoveGADialog
