import Alert from './Alert/Alert'
import AutoRenewModalSkeleton from './Skeletons/AutoRenewModalSkeleton'
import { useTokenExpiryCheck } from '../utils/utils'
import FlexibleButton from './FlexibleButton'

const AutoRenewDialog = ({
  selectedListItems,
  setModalOpen,
  handleEnableAutoRenew,
  getAutoRenewActionType,
  autoRenewLoading,
  autoRenewResponses,
  setAutoRenewResponses,
  closeModalRefreshTenants,
}) => {
  const actionType = getAutoRenewActionType()
  const buttonText =
    actionType === 'enable' ? 'Enable Auto-Renew' : 'Disable Auto-Renew'

  const tenantNames =
    selectedListItems?.map(item => item.companyProfile?.companyName) || []

  const expiryCheck = useTokenExpiryCheck()

  const clickAutoRenew = async () => {
    if (await expiryCheck()) {
      return
    }

    handleEnableAutoRenew(selectedListItems)
  }

  const closeModal = () => {
    setAutoRenewResponses(null)
    setModalOpen(false)
  }

  return (
    <>
      <h3 className='text-3xl font-bold text-inforcer-navy dark:text-gray-200 mb-4'>
        {buttonText}
      </h3>

      {!autoRenewLoading && autoRenewResponses === null && (
        <>
          <p className='mb-2 dark:text-gray-200'>Selected tenants:</p>
          <ul className='mb-4 dark:text-gray-200'>
            {tenantNames.length > 0 ? (
              tenantNames.map((name, index) => <li key={index}>{name}</li>)
            ) : (
              <p>No tenants selected.</p>
            )}
          </ul>
          <div className='dialog-buttons mt-4 flex gap-4'>
            <FlexibleButton onClick={clickAutoRenew} variant='primary'>
              {buttonText}
            </FlexibleButton>
            <FlexibleButton onClick={closeModal} variant='secondary'>
              Close Modal
            </FlexibleButton>
          </div>
        </>
      )}

      {autoRenewLoading && <AutoRenewModalSkeleton />}

      {!autoRenewLoading && autoRenewResponses && (
        <>
          <ul>
            {autoRenewResponses.map((response, index) => (
              <li key={index} className='dark:bg-slate-900'>
                {response.ok ? (
                  <Alert type='success' styles='px-4 py-2'>
                    Successfully updated Auto-Renew for:{' '}
                    <b>{response.responseData.customer.displayName}</b>
                  </Alert>
                ) : (
                  <Alert type='error' styles='px-4 py-2'>
                    {response.message ||
                      `Failed for: <b>${response.responseData.customer.displayName}</b>`}
                  </Alert>
                )}
              </li>
            ))}
          </ul>
          <div className='dialog-buttons mt-4'>
            <FlexibleButton
              onClick={closeModalRefreshTenants}
              variant='secondary'
            >
              Close modal
            </FlexibleButton>
          </div>
        </>
      )}
    </>
  )
}

export default AutoRenewDialog
