import { useState } from 'react'
import { getFromIndexedDB, saveToIndexedDB } from '../utils/idbUtils'

export const globalAdminRoleId = '62e90394-69f5-4237-9190-012177145e10'

const useRemoveGA = () => {
  const [removeGALoading, setRemoveGALoading] = useState(false)
  const [removeGAResponses, setRemoveGAResponses] = useState(null)
  const [pollResult, setPollResult] = useState(null)

  const createBatches = (items, batchSize) => {
    const batches = []
    for (let i = 0; i < items.length; i += batchSize) {
      batches.push(items.slice(i, i + batchSize))
    }
    return batches
  }

  const getAccessToken = async () => {
    try {
      const onboardingState = await getFromIndexedDB('onboardingState')
      if (!onboardingState || !onboardingState.graphToken) {
        throw new Error('No valid access token found in IndexedDB')
      }
      return onboardingState.graphToken
    } catch (error) {
      console.error('Failed to retrieve access token:', error)
      return null
    }
  }

  const formatETag = eTag => {
    if (!eTag) return null
    // eslint-disable-next-line no-useless-escape
    return eTag.replace(/^W\\/, 'W/').replace(/\\\"/g, '"')
  }

  const fetchETag = async relationshipId => {
    try {
      const token = await getAccessToken()
      if (!token) {
        throw new Error('Access token is missing')
      }

      const url = `https://graph.microsoft.com/beta/tenantRelationships/delegatedAdminRelationships/${relationshipId}`
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })

      if (!response.ok) {
        const errorText = await response.text()
        throw new Error(
          `Failed to fetch ETag for ${relationshipId}. Response: ${response.status} - ${errorText}`
        )
      }

      const data = await response.json()
      return data['@odata.etag']
    } catch (error) {
      console.error('Failed to fetch ETag:', error)
      return null
    }
  }

  // Polls the DAR until GA is removed, or we time out
  const pollDAR = async ({
    relationshipId,
    token,
    interval = 5000,
    maxAttempts = 6,
  }) => {
    let attempts = 0

    while (attempts < maxAttempts) {
      attempts++

      // Wait interval ms before checking
      await new Promise(resolve => setTimeout(resolve, interval))

      // Re-fetch the delegated admin relationship
      const getResp = await fetch(
        `https://graph.microsoft.com/beta/tenantRelationships/delegatedAdminRelationships/${relationshipId}`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )

      if (!getResp.ok) {
        console.warn(
          `Poll attempt #${attempts} failed with status ${getResp.status}`
        )
        continue // We'll just try again until maxAttempts or success
      }

      const data = await getResp.json()
      const roles = data?.accessDetails?.unifiedRoles || []
      // If we see that the GA role is no longer present, we can consider it done
      const hasGA = roles.some(
        role => role.roleDefinitionId === globalAdminRoleId
      )

      if (!hasGA) {
        console.log(`GA role is removed after ${attempts} attempt(s).`)
        // We can exit the loop early
        return { success: true, data }
      }
    }

    // If we exit the loop, we never saw GA removed
    return { success: false, data: null }
  }

  /**
   * removeGlobalAdminRole
   *  GET existing DAR
   * Filter out the GA role
   * PATCH with new roles
   * If 202 => poll to confirm
   */
  const removeGlobalAdminRole = async (relationshipId, eTag) => {
    try {
      const token = await getAccessToken()
      if (!token) {
        throw new Error('Access token is missing')
      }

      // GET the existing relationship
      const getResp = await fetch(
        `https://graph.microsoft.com/beta/tenantRelationships/delegatedAdminRelationships/${relationshipId}`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      if (!getResp.ok) {
        const errorText = await getResp.text()
        throw new Error(
          `Failed to retrieve existing roles for ${relationshipId}. ${getResp.status} - ${errorText}`
        )
      }
      const getData = await getResp.json()
      const { unifiedRoles = [] } = getData.accessDetails || {}

      const updatedRoles = unifiedRoles.filter(
        role => role.roleDefinitionId !== globalAdminRoleId
      )

      const patchBody = {
        accessDetails: {
          unifiedRoles: updatedRoles,
        },
      }

      // PATCH the relationship with updated roles
      const patchResp = await fetch(
        `https://graph.microsoft.com/beta/tenantRelationships/delegatedAdminRelationships/${relationshipId}`,
        {
          method: 'PATCH',
          headers: {
            Authorization: `Bearer ${token}`,
            'If-Match': eTag,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(patchBody),
        }
      )

      let patchData = null
      if (
        patchResp.ok &&
        patchResp.status !== 204 &&
        patchResp.status !== 202
      ) {
        patchData = await patchResp.json()
      }

      // If the server accepted the request but hasn't finished, we poll
      if (patchResp.ok && patchResp.status === 202) {
        console.log('Initial remove GA patch returned 202, polling...')
        const pollResultLocal = await pollDAR({
          relationshipId,
          token,
          interval: 5000,
          maxAttempts: 6,
        })
        setPollResult(pollResultLocal)
      }

      return {
        ok: patchResp.ok,
        status: patchResp.status,
        relationshipId,
        responseData: patchData,
      }
    } catch (error) {
      console.error(`Error removing GA for ${relationshipId}:`, error)
      return { ok: false, relationshipId, error: error.message }
    }
  }

  // Batches selected tenants, calls removeGlobalAdminRole, then updates local state.
  const handleRemoveGlobalAdmin = async selectedItems => {
    try {
      if (!selectedItems || selectedItems.length === 0) return

      setRemoveGALoading(true)
      const results = []
      const batchSize = 20

      // Create batches from selected items
      const batches = createBatches(selectedItems, batchSize)

      // For each batch, fetch ETag + remove GA
      for (const batch of batches) {
        const batchRequests = await Promise.all(
          batch.map(async item => {
            const eTag = await fetchETag(item.relationshipId)
            if (!eTag) {
              return {
                relationshipId: item.relationshipId,
                ok: false,
                message: 'No ETag found',
              }
            }

            const formattedETag = formatETag(eTag)

            const removeResult = await removeGlobalAdminRole(
              item.relationshipId,
              formattedETag
            )

            return removeResult
          })
        )
        results.push(...batchRequests)
      }

      setRemoveGALoading(false)
      setRemoveGAResponses(results)

      // Update local IndexedDB if we had success
      const onboardingState = (await getFromIndexedDB('onboardingState')) || {}
      if (!onboardingState.customers) {
        await saveToIndexedDB('onboardingState', onboardingState)
        return
      }

      // For each success => remove GA from local array
      onboardingState.customers = onboardingState.customers.map(customer => {
        const matchingResponse = results.find(
          res =>
            res.ok &&
            // If the updated patch data includes 'customer.tenantId', you can do that check
            // but if 'responseData?.customer' is missing for 202 or 204, you might rely on the relationshipId
            res.relationshipId === customer.relationshipId
        )
        if (matchingResponse) {
          // Filter out GA from unifiedRoles
          const { unifiedRoles = [] } = customer
          const newRoles = unifiedRoles.filter(
            role => role.roleDefinitionId !== globalAdminRoleId
          )
          return {
            ...customer,
            unifiedRoles: newRoles,
          }
        }
        return customer
      })

      await saveToIndexedDB('onboardingState', onboardingState)
    } catch (error) {
      console.error('Failed to remove GA:', error)
      setRemoveGALoading(false)
    }
  }

  return {
    removeGALoading,
    removeGAResponses,
    setRemoveGAResponses,
    handleRemoveGlobalAdmin,
    pollResult,
  }
}

export default useRemoveGA
