/* eslint-disable */

import { openDB } from 'idb'

// Open (or create) the database
const dbPromise = openDB('appDB', 1, {
  upgrade(db) {
    if (!db.objectStoreNames.contains('storage')) {
      db.createObjectStore('storage')
    }
  },
})

// Save data to IndexedDB
export const saveToIndexedDB = async (key, value) => {
  const db = await dbPromise
  await db.put('storage', value, key)
}

// Get data from IndexedDB
export const getFromIndexedDB = async key => {
  const db = await dbPromise
  return await db.get('storage', key)
}

// Remove data from IndexedDB
export const removeFromIndexedDB = async key => {
  const db = await dbPromise
  await db.delete('storage', key)
}

// Clear all IndexedDB storage
export const clearIndexedDB = async () => {
  const db = await dbPromise
  await db.clear('storage')
}

export const sanitizeForIndexedDB = obj => {
  return JSON.parse(
    JSON.stringify(obj, (key, value) => {
      // Remove any React elements (Symbol(react.element))
      if (typeof value === 'object' && value !== null && value.$$typeof) {
        return undefined
      }
      return value
    })
  )
}
