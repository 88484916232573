import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  ResponsiveContainer,
  Tooltip,
  Legend,
} from 'recharts'

const LicenseBarChart = ({
  customerCount,
  tenantsWithBusinessBasic,
  tenantsWithBusinessStandard,
  tenantsWithBusinessPremium,
  tenantsWithBusinessE1,
  tenantsWithBusinessE3,
  tenantsWithBusinessE5,
  realTheme,
}) => {
  const ensureVisible = value => (value === 0 ? 0.0 : value)

  const data = [
    {
      name: "M365 Bus' Basic",
      Licenses: ensureVisible(tenantsWithBusinessBasic),
      Remaining: ensureVisible(customerCount - tenantsWithBusinessBasic),
    },
    {
      name: "M365 Bus' Standard",
      Licenses: ensureVisible(tenantsWithBusinessStandard),
      Remaining: ensureVisible(customerCount - tenantsWithBusinessStandard),
    },
    {
      name: "M365 Bus' Premium",
      Licenses: ensureVisible(tenantsWithBusinessPremium),
      Remaining: ensureVisible(customerCount - tenantsWithBusinessPremium),
    },
    {
      name: 'M365 E1',
      Licenses: ensureVisible(tenantsWithBusinessE1),
      Remaining: ensureVisible(customerCount - tenantsWithBusinessE1),
    },
    {
      name: 'M365 E3',
      Licenses: ensureVisible(tenantsWithBusinessE3),
      Remaining: ensureVisible(customerCount - tenantsWithBusinessE3),
    },
    {
      name: 'M365 E5',
      Licenses: ensureVisible(tenantsWithBusinessE5),
      Remaining: ensureVisible(customerCount - tenantsWithBusinessE5),
    },
  ]

  const barColor = realTheme === 'dark' ? '#4686CE' : '#102550'

  return (
    <ResponsiveContainer
      width='100%'
      height={260}
      className='flex items-center'
    >
      <BarChart
        data={data}
        layout='vertical'
        margin={{
          top: 10,
          right: 10,
          bottom: 10,
          left: 10,
        }}
      >
        <XAxis type='number' />
        <YAxis width={150} dataKey='name' type='category' />
        <Tooltip />
        <Legend />
        <Bar dataKey='Licenses' barSize={15} stackId='a' fill={barColor} />
      </BarChart>
    </ResponsiveContainer>
  )
}

export default LicenseBarChart
