import React from 'react'
import ReactDOM from 'react-dom/client'
import { SnackbarProvider, useSnackbar } from 'notistack'
import PartnerCenterAssessment from './journeys/PartnerCenterAssessment'
import './index.css'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <>
    <SnackbarProvider>
      <PartnerCenterAssessment />
    </SnackbarProvider>
  </>
)
