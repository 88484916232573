import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCheckCircle,
  faCircleExclamation,
  faCircleInfo,
  faCircleXmark,
} from '@fortawesome/free-solid-svg-icons'
import './Alert.css'

const Alert = ({ children, title, type, styles }) => {
  const buildIcon = () => {
    switch (type) {
      case 'success':
        return <FontAwesomeIcon icon={faCheckCircle} />
      case 'warning':
        return <FontAwesomeIcon icon={faCircleExclamation} />
      case 'error':
        return <FontAwesomeIcon icon={faCircleXmark} />
      default:
        return <FontAwesomeIcon icon={faCircleInfo} />
    }
  }

  return (
    <div className={`alert ${type} ${styles}`} role='alert'>
      <div className='flex items-center'>
        <span className='alert-icon'>{buildIcon()}</span>
        <div className='mx-3'>
          {title && <h4 className='font-semibold'>{title}</h4>}
          {children}
        </div>
      </div>
    </div>
  )
}

export default Alert
